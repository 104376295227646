import { SvgProps } from "@design-system/Icon/types";

export const Award = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7.05133 4.10531C8.36408 2.79255 10.1446 2.05505 12.0011 2.05505C13.8576 2.05505 15.6381 2.79255 16.9508 4.10531C18.2636 5.41806 19.0011 7.19854 19.0011 9.05505C19.0011 10.9116 18.2636 12.692 16.9508 14.0048C15.6381 15.3176 13.8576 16.0551 12.0011 16.0551C10.1446 16.0551 8.36408 15.3176 7.05133 14.0048C5.73857 12.692 5.00108 10.9116 5.00108 9.05505C5.00108 7.19854 5.73857 5.41806 7.05133 4.10531ZM12.0011 4.05505C10.675 4.05505 9.40322 4.58184 8.46554 5.51952C7.52786 6.4572 7.00108 7.72897 7.00108 9.05505C7.00108 10.3811 7.52786 11.6529 8.46554 12.5906C9.40322 13.5283 10.675 14.0551 12.0011 14.0551C13.3272 14.0551 14.5989 13.5283 15.5366 12.5906C16.4743 11.6529 17.0011 10.3811 17.0011 9.05505C17.0011 7.72897 16.4743 6.4572 15.5366 5.51952C14.5989 4.58184 13.3272 4.05505 12.0011 4.05505Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M16.6971 11.189C17.1754 10.9129 17.787 11.0768 18.0631 11.5551L21.4631 17.4441C21.6475 17.7634 21.6411 18.1584 21.4466 18.4717C21.252 18.785 20.9008 18.9657 20.5327 18.942L17.6001 18.7529L16.2975 21.3882C16.1341 21.7188 15.8019 21.9326 15.4333 21.9445C15.0647 21.9564 14.7194 21.7644 14.535 21.445L11.135 15.555C10.8589 15.0767 11.0228 14.4651 11.5011 14.189C11.9795 13.9129 12.591 14.0768 12.8671 14.5551L15.3328 18.8265L16.1026 17.2689C16.2815 16.907 16.6605 16.6881 17.0634 16.7141L18.7968 16.8259L16.331 12.5551C16.0549 12.0768 16.2188 11.4652 16.6971 11.189Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7.30301 11.189C7.78132 11.4651 7.94525 12.0767 7.66914 12.555L5.20349 16.8264L6.93645 16.7141C7.33941 16.688 7.71852 16.9069 7.89749 17.2688L8.66732 18.8258L11.1331 14.5551C11.4092 14.0768 12.0208 13.9129 12.4991 14.189C12.9774 14.4652 13.1412 15.0768 12.8651 15.5551L9.4651 21.4441C9.2807 21.7634 8.93548 21.9554 8.56687 21.9435C8.19827 21.9317 7.86612 21.7179 7.70266 21.3873L6.40023 18.7531L3.4677 18.943C3.09964 18.9668 2.74834 18.7861 2.55369 18.4728C2.35903 18.1595 2.35262 17.7645 2.53701 17.4451L5.93701 11.5551C6.21312 11.0768 6.8247 10.9129 7.30301 11.189Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
