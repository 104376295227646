import { SvgProps } from "@design-system/Icon/types";

export const BuildingStore = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M2 21C2 20.4477 2.44772 20 3 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4.10557 2.55279C4.27496 2.214 4.62123 2 5 2H19C19.3788 2 19.725 2.214 19.8944 2.55279L21.8944 6.55279C21.9639 6.69164 22 6.84476 22 7V8C22 9.06087 21.5786 10.0783 20.8284 10.8284C20.0783 11.5786 19.0609 12 18 12C16.9391 12 15.9217 11.5786 15.1716 10.8284C15.1123 10.7692 15.0551 10.7082 15 10.6458C14.9449 10.7082 14.8877 10.7692 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C9.11232 10.7692 9.05511 10.7082 9 10.6458C8.94489 10.7082 8.88768 10.7692 8.82843 10.8284C8.07828 11.5786 7.06087 12 6 12C4.93913 12 3.92172 11.5786 3.17157 10.8284C2.42143 10.0783 2 9.06087 2 8V7C2 6.84476 2.03615 6.69164 2.10557 6.55279L4.10557 2.55279ZM10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8H10ZM4.61803 6L5.61803 4H18.382L19.382 6H4.61803ZM16 8C16 8.53043 16.2107 9.03914 16.5858 9.41421C16.9609 9.78929 17.4696 10 18 10C18.5304 10 19.0391 9.78929 19.4142 9.41421C19.7893 9.03914 20 8.53043 20 8H16ZM4 8H8C8 8.53043 7.78929 9.03914 7.41421 9.41421C7.03914 9.78929 6.53043 10 6 10C5.46957 10 4.96086 9.78929 4.58579 9.41421C4.21071 9.03914 4 8.53043 4 8Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M5 9.84998C5.55228 9.84998 6 10.2977 6 10.85V21C6 21.5523 5.55228 22 5 22C4.44772 22 4 21.5523 4 21V10.85C4 10.2977 4.44772 9.84998 5 9.84998Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M19 9.84998C19.5523 9.84998 20 10.2977 20 10.85V21C20 21.5523 19.5523 22 19 22C18.4477 22 18 21.5523 18 21V10.85C18 10.2977 18.4477 9.84998 19 9.84998Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 16C10.7348 16 10.4804 16.1054 10.2929 16.2929C10.1054 16.4804 10 16.7348 10 17V21C10 21.5523 9.55228 22 9 22C8.44772 22 8 21.5523 8 21V17C8 16.2043 8.31607 15.4413 8.87868 14.8787C9.44129 14.3161 10.2043 14 11 14H13C13.7957 14 14.5587 14.3161 15.1213 14.8787C15.6839 15.4413 16 16.2043 16 17V21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21V17C14 16.7348 13.8946 16.4804 13.7071 16.2929C13.5196 16.1054 13.2652 16 13 16H11Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
