export default {
    production: true,
    base_url: "https://app.wp-umbrella.com",
    api_url: "https://api-umb1.wp-umbrella.com",
    private_api_url: "https://private-api-umb1.wp-umbrella.com",
    api: {
        client_id: "a64e29a29827dffdbe721c4ada474a68dff0",
        client_secret: "e9738014eccd353eb8344dd28eaae52c5ba31c1d7094d342",
        scope: "application",
    },
    meilisearch_public_url: "https://search-data.wp-umbrella.com",
    stripe: {
        webhooks: {
            invoices: "whsec_qlyfcbKnCPevQf38nFW8Wvk9M0AKgBx4",
        },
        hourly_backup: [
            {
                slug: "wpu_hourly_backup_1",
                id: "price_1LVXInIcL5h0Eadw9W8fAJeO",
                active: true,
                price: 2.49,
                name: "Add-on - Hourly Backup",
            },
        ],
        pay_as_you_go: [
            {
                slug: "wpu_monthly_payg_1",
                active: false,
                id: "price_1KFIghIcL5h0EadwajoMEO60",
                type: "month",
                price: 1.49,
                name: "Premium",
                limit_monitor: 1,
                limit_projects: 10000,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 30,
            },
            {
                slug: "wpu_monthly_payg_2",
                active: false,
                id: "price_1LFEfgIcL5h0EadwePErqj19",
                type: "month",
                price: 1.99,
                name: "Premium",
                limit_monitor: 1,
                limit_projects: 10000,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 30,
            },
            {
                slug: "wpu_monthly_payg_3",
                active: true,
                id: "price_1PqWUPIcL5h0EadwGmcLvSHa",
                type: "month",
                price: 2.49,
                name: "Premium",
                limit_monitor: 1,
                limit_projects: 10000,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 30,
            },
        ],
        plans: [
            {
                slug: "free",
                id: "free",
                type: "month",
                price: 0,
                limit_monitor: 1,
                limit_projects: 1,
                name: "Free",
                max_regions: 1,
                limit_history: 7,
                limit_email_notifications: 1,
            },
            {
                slug: "wpu_1",
                active: false,
                id: "price_1JJJ0vIcL5h0EadwDXBXTwNN",
                type: "month",
                price: 4.99,
                name: "WP Umbrella (1 site)",
                limit_monitor: 1,
                limit_projects: 1,
                max_regions: 16,
                limit_history: 30,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_3",
                active: false,
                id: "price_1JJJ0uIcL5h0EadwDeWnsqTl",
                type: "month",
                price: 9.99,
                name: "WP Umbrella (3 sites)",
                limit_monitor: 1,
                limit_projects: 3,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_10",
                active: false,
                id: "price_1JJJ0tIcL5h0EadwYrVcK4ly",
                type: "month",
                price: 24.99,
                name: "WP Umbrella (10 sites)",
                limit_monitor: 1,
                limit_projects: 10,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_5",
                active: true,
                id: "price_1JhUafIcL5h0EadwApxS5iE3",
                type: "month",
                price: 14.99,
                name: "WP Umbrella (5 sites)",
                limit_monitor: 1,
                limit_projects: 5,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_20",
                active: true,
                id: "price_1JhUbOIcL5h0Eadwnpb5NNcB",
                type: "month",
                price: 49.99,
                name: "WP Umbrella (20 sites)",
                limit_monitor: 1,
                limit_projects: 20,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_50",
                active: true,
                id: "price_1JJJ0pIcL5h0EadwbKx1hRmv",
                type: "month",
                price: 99.99,
                name: "WP Umbrella (50 sites)",
                limit_monitor: 1,
                limit_projects: 50,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_100",
                active: true,
                id: "price_1JhUcAIcL5h0Eadw2CdnzBlS",
                type: "month",
                price: 149.99,
                name: "WP Umbrella (100 sites)",
                limit_monitor: 1,
                limit_projects: 100,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_unlimited",
                active: true,
                id: "price_1JhUckIcL5h0EadwNnk7zlVA",
                type: "month",
                price: 249.99,
                name: "WP Umbrella (Unlimited)",
                limit_monitor: 1,
                limit_projects: 5000,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_1",
                active: false,
                id: "price_1JJJ0vIcL5h0EadwL8XJmwnp",
                type: "annual",
                price: 49.99,
                name: "WP Umbrella (1 site)",
                limit_monitor: 1,
                limit_projects: 1,
                max_regions: 16,
                limit_history: 30,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_3",
                active: false,
                id: "price_1JJJ0uIcL5h0EadwuFrTyZhk",
                type: "annual",
                price: 99.99,
                name: "WP Umbrella (3 sites)",
                limit_monitor: 1,
                limit_projects: 3,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_5",
                active: true,
                id: "price_1JhUafIcL5h0EadwcKBZxuCo",
                type: "annual",
                price: 149.99,
                name: "WP Umbrella (5 sites)",
                limit_monitor: 1,
                limit_projects: 5,
                max_regions: 16,
                limit_history: 30,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_10",
                active: false,
                id: "price_1JJJ0tIcL5h0EadwCBUOt7Eo",
                type: "annual",
                price: 249.99,
                name: "WP Umbrella (10 sites)",
                limit_monitor: 1,
                limit_projects: 10,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_20",
                active: true,
                id: "price_1JhUbOIcL5h0EadwUyot7ISd",
                type: "annual",
                price: 499.99,
                name: "WP Umbrella (20 sites)",
                limit_monitor: 1,
                limit_projects: 20,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_50",
                active: true,
                id: "price_1JJJ0pIcL5h0Eadwbff03KqK",
                type: "annual",
                price: 999.99,
                name: "WP Umbrella (50 sites)",
                limit_monitor: 1,
                limit_projects: 50,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_100",
                active: true,
                id: "price_1JhUcAIcL5h0EadwQkHCSehg",
                type: "annual",
                price: 1499.99,
                name: "WP Umbrella (100 sites)",
                limit_monitor: 1,
                limit_projects: 100,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "wpu_unlimited",
                active: true,
                id: "price_1JhUckIcL5h0EadwMvrtmSbx",
                type: "annual",
                price: 2499.99,
                name: "WP Umbrella (Unlimited)",
                limit_monitor: 1,
                limit_projects: 5000,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "starter",
                active: false,
                id: "price_1HCL80IcL5h0EadwJJ5siKqX",
                type: "month",
                price: 4.99,
                name: "Starter",
                limit_monitor: 1,
                limit_projects: 1,
                max_regions: 16,
                limit_history: 30,
                limit_email_notifications: 10,
            },
            {
                slug: "personal",
                active: false,
                id: "price_1HCL6tIcL5h0EadwetXchXhz",
                type: "month",
                price: 9.99,
                name: "Personal",
                limit_monitor: 1,
                limit_projects: 3,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "standard",
                active: false,
                id: "price_1HaqbDIcL5h0EadwxpivRJlX",
                type: "month",
                price: 24.99,
                name: "Standard",
                limit_monitor: 1,
                limit_projects: 10,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "business",
                active: false,
                id: "price_1HaqeVIcL5h0EadwjB2rVHI2",
                type: "month",
                price: 99.99,
                name: "Business",
                limit_monitor: 1,
                limit_projects: 50,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "starter",
                active: false,
                id: "price_1HCL8ZIcL5h0EadwMr5sXLHh",
                type: "annual",
                price: 49.99,
                name: "Starter",
                limit_monitor: 1,
                limit_projects: 1,
                max_regions: 16,
                limit_history: 30,
                limit_email_notifications: 10,
            },
            {
                slug: "personal",
                active: false,
                id: "price_1HCL75IcL5h0EadwYIxFP03G",
                type: "annual",
                price: 99.99,
                name: "Personal",
                limit_monitor: 1,
                limit_projects: 3,
                max_regions: 16,
                limit_history: 60,
                limit_email_notifications: 10,
            },
            {
                slug: "standard",
                active: false,
                id: "price_1HaqbDIcL5h0EadwnhjYatUj",
                type: "annual",
                price: 249.99,
                name: "Standard",
                limit_monitor: 1,
                limit_projects: 10,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
            {
                slug: "business",
                active: false,
                id: "price_1HaqekIcL5h0EadwjCYEpbHh",
                type: "annual",
                price: 999.99,
                name: "Business",
                limit_monitor: 1,
                limit_projects: 50,
                max_regions: 16,
                limit_history: 90,
                limit_email_notifications: 10,
            },
        ],
    },
};
