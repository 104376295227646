import { Transition } from "@headlessui/react";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { isEmpty, isNil } from "lodash";
import React, { Fragment, useContext } from "react";
import {
    NotificationContext,
    TypeNotification,
} from "../../../contexts/NotificationContext";

const PopupNotification = () => {
    const { values, actions } = useContext(NotificationContext);

    return (
        <>
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
                style={{
                    zIndex: 10000,
                }}
            >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={values.show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {values.data.type ===
                                            TypeNotification.SUCCESS && (
                                            <CheckCircleIcon
                                                className="h-6 w-6 text-green-400"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {values.data.type ===
                                            TypeNotification.ERROR && (
                                            <ExclamationCircleIcon
                                                className="h-6 w-6 text-red-400"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        {!isNil(values.data.title) &&
                                            !isEmpty(values.data.title) && (
                                                <p className="text-sm font-medium text-gray-900">
                                                    {values.data.title}
                                                </p>
                                            )}
                                        {!isNil(values.data.message) &&
                                            !isEmpty(values.data.message) && (
                                                <p className="mt-1 text-sm text-gray-500">
                                                    {values.data.message}
                                                </p>
                                            )}
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button
                                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                actions.setShow(false);
                                            }}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XMarkIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
};

export default PopupNotification;
