import { isFunction } from "lodash";
import { System } from "@design-system/index";
import { TableColumn, TableKey, TableRow } from "@design-system/Table/types";
import { useTable } from "@design-system/Table/hooks/useTable";
import { ReactNode } from "react";

type ChildFunction<T> = (props: {
    item: T;
    isChecked: boolean;
    isExpanded: boolean;
}) => JSX.Element;
type ChildrenProp<T> = ReactNode | ChildFunction<T>;

interface Props<T, C> {
    rowKey: TableKey;
    item: TableRow<T, C>;
    columns?: Array<TableColumn>;
    className?: string;
    columnsClassname?: string;
    innerRowClassName?: string;
    children: ChildrenProp<T>;
    isDisabled?: boolean;
}

export const TableRowWithActions = <T, C = {}>({
    rowKey,
    item,
    columns,
    children,
    className = "",
    innerRowClassName = "",
}: Props<T, C>) => {
    const { state } = useTable();
    const { checkedRows, expandedRows, isStacked } = state;

    const isChecked = checkedRows.has(rowKey);
    const isExpanded = expandedRows.has(rowKey);

    return (
        <System.Table.Row item={item} className={className} isStacked={isStacked}>
            <System.Table.InnerRowWithActions
                rowKey={rowKey}
                item={item}
                columns={columns}
                className={innerRowClassName}
            >
                {isFunction(children)
                    ? children({
                          item: item,
                          isChecked: isChecked,
                          isExpanded: isExpanded,
                      })
                    : children}
            </System.Table.InnerRowWithActions>
        </System.Table.Row>
    );
};
