import { Transition } from "@headlessui/react";
import { cn } from "@helpers/utils";
import { Fragment } from "react";
import { System } from "..";
import { PasswordValidity } from "./Input.types";

interface PasswordRequirementsTooltipProps {
    open: boolean;
    passwordValidity: PasswordValidity;
}

export const PasswordRequirementsTooltip = ({
    open,
    passwordValidity,
}: PasswordRequirementsTooltipProps) => {
    const validityItems = [
        {
            text: "at least 8 characters",
            isValid: passwordValidity.minLength,
        },
        {
            text: "at least one special character",
            isValid: passwordValidity.specialChar,
        },
        {
            text: "at least one number",
            isValid: passwordValidity.hasNumber,
        },
    ];

    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            show={open}
        >
            <div className="absolute right-full top-0 mr-4 z-50 transform w-screen max-w-60">
                <div className="overflow-hidden rounded-lg shadow-lg p-4 bg-white border border-main-grey-100">
                    <ul className="text-body-sm space-y-2">
                        {validityItems.map(({ text, isValid }) => (
                            <li
                                key={text}
                                className={cn(
                                    {
                                        "text-primary-darkest": !isValid,
                                        "text-success-base": isValid,
                                    },
                                    "font-medium flex items-center"
                                )}
                            >
                                <System.Svg.Check
                                    size={20}
                                    className={cn(
                                        {
                                            "bg-main-grey-200": !isValid,
                                            "bg-success-base text-white":
                                                isValid,
                                        },
                                        "mr-2 rounded-full p-1 shrink-0"
                                    )}
                                />
                                <span className="text-inherit shrink-0">
                                    {text}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Transition>
    );
};
