import { toast } from "sonner";
import Colors from "tailwindcss/colors";
import DefaultConfig from "tailwindcss/defaultTheme";

function useNotifications() {
    const handleNotifySuccess = ({
        title = "Success",
        message = "The action was successfully completed.",
    }: {
        title?: string;
        message?: React.ReactNode | string;
        timeout?: number;
    } = {}) => {
        toast.success(title, {
            description: message,
            style: {
                backgroundColor: "#ffffff",
                borderColor: Colors.gray[100],
                boxShadow: DefaultConfig.boxShadow.sm,
            },
        });
    };

    const handleNotifyError = ({
        title = "Error",
        message = "An error occurred during the action.",
    }: {
        title?: string;
        message?: React.ReactNode | string;
        timeout?: number;
    }) => {
        toast.error(title, {
            description: message,
            style: {
                backgroundColor: "#ffffff",
                borderColor: Colors.gray[100],
                boxShadow: DefaultConfig.boxShadow.sm,
            },
        });
    };
    const handleNotifyMessage = ({
        title = "",
        message = "",
    }: {
        title?: string;
        message?: React.ReactNode | string;
        timeout?: number;
    }) => {
        toast.message(title, {
            description: message,
        });
    };

    return {
        handleNotifySuccess,
        handleNotifyError,
        handleNotifyMessage,
    };
}

export default useNotifications;
