import { SvgProps } from "@design-system/Icon/types";

export const G2 = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <path
                    fill="#FF492C"
                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                />
                <path
                    fill="#F6F6FE"
                    d="M17.1936 9.19201H15.144C15.1992 8.87041 15.3984 8.69041 15.8016 8.48641L16.1784 8.29441C16.8528 7.94881 17.2128 7.55761 17.2128 6.91921C17.2128 6.51841 17.0568 6.20161 16.7472 5.97361C16.4376 5.74561 16.0728 5.63281 15.6456 5.63281C15.3151 5.62892 14.9907 5.7215 14.712 5.89921C14.4312 6.07201 14.2224 6.29521 14.0928 6.57361L14.6856 7.16881C14.916 6.70321 15.2496 6.47521 15.6888 6.47521C16.0608 6.47521 16.2888 6.66721 16.2888 6.93361C16.2888 7.15681 16.1784 7.34161 15.7512 7.55761L15.5088 7.67521C14.9832 7.94161 14.6184 8.24641 14.4072 8.59201C14.196 8.93761 14.0928 9.37201 14.0928 9.89761V10.0416H17.1936V9.19201ZM16.92 11.0208H13.5264L11.8296 13.9584H15.2232L16.92 16.8984L18.6168 13.9584L16.92 11.0208Z"
                />
                <path
                    fill="#F6F6FE"
                    d="M12.1224 15.9192C9.96242 15.9192 8.20322 14.16 8.20322 12C8.20322 9.83999 9.96242 8.08079 12.1224 8.08079L13.464 5.27519C13.0222 5.18734 12.5729 5.14312 12.1224 5.14319C8.33522 5.14319 5.26562 8.21279 5.26562 12C5.26562 15.7872 8.33522 18.8568 12.1224 18.8568C13.5738 18.8595 14.988 18.3987 16.1592 17.5416L14.676 14.9688C13.9664 15.5817 13.0601 15.9191 12.1224 15.9192Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
