import { SvgProps } from "@design-system/Icon/types";

export const Activity = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    strokeWidth={strokeWidth}
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M13.9431 3.00163C14.3802 2.97674 14.7826 3.239 14.9363 3.64889L17.693 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H17C16.5832 13 16.21 12.7414 16.0637 12.3511L14.1913 7.35805L10.9701 20.2425C10.864 20.6672 10.4939 20.9735 10.0569 20.9984C9.61982 21.0233 9.21738 20.761 9.06367 20.3511L6.307 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H7C7.41684 11 7.78997 11.2586 7.93633 11.6489L9.80873 16.642L13.0299 3.75748C13.136 3.33279 13.5061 3.02652 13.9431 3.00163Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
};
