import { SvgProps } from "@design-system/Icon/types";

export const Trash = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4.91695 6.00345C5.46733 5.95759 5.95068 6.36658 5.99655 6.91695L6.99655 18.917C6.99885 18.9446 7 18.9723 7 19C7 19.2652 7.10536 19.5196 7.29289 19.7071C7.48043 19.8946 7.73478 20 8 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19C17 18.9723 17.0012 18.9446 17.0035 18.917L18.0035 6.91695C18.0493 6.36658 18.5327 5.95759 19.083 6.00345C19.6334 6.04932 20.0424 6.53267 19.9965 7.08305L18.9997 19.0458C18.9878 19.8249 18.6731 20.5695 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H8C7.20435 22 6.44129 21.6839 5.87868 21.1213C5.32687 20.5695 5.01223 19.8249 5.00035 19.0458L4.00345 7.08305C3.95759 6.53267 4.36658 6.04932 4.91695 6.00345Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V4H10V7C10 7.55228 9.55228 8 9 8C8.44772 8 8 7.55228 8 7V4C8 3.46957 8.21071 2.96086 8.58579 2.58579Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
