import { SvgProps } from "@design-system/Icon/types";

export const ArrowBarBoth = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M1 12C1 11.4477 1.44772 11 2 11H8C8.55229 11 9 11.4477 9 12C9 12.5523 8.55229 13 8 13H2C1.44772 13 1 12.5523 1 12Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711L3.41421 12L5.70711 14.2929C6.09763 14.6834 6.09763 15.3166 5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071L1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M15 12C15 11.4477 15.4477 11 16 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H16C15.4477 13 15 12.5523 15 12Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071C17.9024 15.3166 17.9024 14.6834 18.2929 14.2929L20.5858 12L18.2929 9.70711C17.9024 9.31658 17.9024 8.68342 18.2929 8.29289Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 3C12.5523 3 13 3.44772 13 4L13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20L11 4C11 3.44772 11.4477 3 12 3Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
