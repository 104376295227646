import { System } from "@design-system/index";
import { Transition } from "@headlessui/react";
import { isFunction } from "lodash";
import { Fragment } from "react";

export interface IBulkAction {
    label: string | React.ReactNode;
    key?: string;
    onClick: () => void;
    icon?: React.ReactNode;
    isDisabled?: boolean | (() => boolean);
    loading?: boolean;
    tooltipDisabled?: string;
}

interface Props {
    className?: string;
    isShowing: boolean;
    actions: IBulkAction[];
    size?: "default" | "large";
}

export const BulkActionsList = ({
    actions,
    isShowing,
    className = "",
    size = "default",
}: Props) => {
    return (
        <Transition
            show={isShowing}
            enter="transition duration-100 ease-out"
            enterFrom="transform translate-y-4 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform translate-y-4 opacity-0"
            as={Fragment}
        >
            <System.Content.BulkActions.Container
                className={className}
                size={size}
            >
                <System.Table.BulkActions.CheckedRows />
                <div className="flex gap-4">
                    {actions.map((action, index) => {
                        let key = String(index);
                        if (action.key) {
                            key = action.key;
                        } else if (typeof action.label === "string") {
                            key = action.label.toLowerCase().replace(" ", "-");
                        }

                        return (
                            <System.Content.BulkActions.Button
                                key={key}
                                disabled={
                                    isFunction(action.isDisabled)
                                        ? action.isDisabled()
                                        : action.isDisabled
                                }
                                onClick={action.onClick}
                            >
                                {action.icon}
                                {action.label}
                            </System.Content.BulkActions.Button>
                        );
                    })}
                </div>
            </System.Content.BulkActions.Container>
        </Transition>
    );
};
