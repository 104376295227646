import { ButtonDefault } from "./Default";
import { ButtonPrimary } from "./Primary";
import { ButtonOutlined } from "./Outlined";
import { ButtonDanger } from "./Danger";
import { ButtonLightDanger } from "./LightDanger";
import { ButtonToggle } from "./Toggle";

export const Button = {
    Danger: ButtonDanger,
    Default: ButtonDefault,
    LightDanger: ButtonLightDanger,
    Outlined: ButtonOutlined,
    Primary: ButtonPrimary,
    Toggle: ButtonToggle,
};
