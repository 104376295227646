import { SvgProps } from "@design-system/Icon/types";

export const Zip = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7 4C6.73479 4 6.48043 4.10536 6.2929 4.29289C6.10536 4.48043 6 4.73478 6 5L6 19.0015C5.99974 19.1773 6.04583 19.35 6.13361 19.5023C6.22139 19.6546 6.34776 19.7811 6.5 19.869C6.97829 20.1451 7.14217 20.7567 6.86603 21.235C6.58989 21.7133 5.9783 21.8772 5.5 21.601C5.0433 21.3373 4.66416 20.9579 4.40082 20.501C4.13762 20.0444 3.99937 19.5264 4 18.9994C4 18.9997 4 18.9991 4 18.9994V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V19C20 19.7957 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H16C15.4477 22 15 21.5523 15 21C15 20.4477 15.4477 20 16 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V8.41421L13.5858 4H7Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 18C10.7348 18 10.4804 18.1054 10.2929 18.2929C10.1054 18.4804 10 18.7348 10 19V21H12V19C12 18.7348 11.8946 18.4804 11.7071 18.2929C11.5196 18.1054 11.2652 18 11 18ZM8.87868 16.8787C9.44129 16.3161 10.2044 16 11 16C11.7957 16 12.5587 16.3161 13.1213 16.8787C13.6839 17.4413 14 18.2043 14 19V21C14 21.5304 13.7893 22.0391 13.4142 22.4142C13.0391 22.7893 12.5304 23 12 23H10C9.46957 23 8.96086 22.7893 8.58579 22.4142C8.21072 22.0391 8 21.5304 8 21V19C8 18.2043 8.31607 17.4413 8.87868 16.8787Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M9 5C9 4.44772 9.44772 4 10 4H11C11.5523 4 12 4.44772 12 5C12 5.55228 11.5523 6 11 6H10C9.44772 6 9 5.55228 9 5Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 7C11 6.44772 11.4477 6 12 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H12C11.4477 8 11 7.55228 11 7Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 7C11 6.44772 11.4477 6 12 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H12C11.4477 8 11 7.55228 11 7Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 7C11 6.44772 11.4477 6 12 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H12C11.4477 8 11 7.55228 11 7Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M9 9C9 8.44772 9.44772 8 10 8H11C11.5523 8 12 8.44772 12 9C12 9.55228 11.5523 10 11 10H10C9.44772 10 9 9.55228 9 9Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 11C11 10.4477 11.4477 10 12 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H12C11.4477 12 11 11.5523 11 11Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M9 13C9 12.4477 9.44772 12 10 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H10C9.44772 14 9 13.5523 9 13Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 15C11 14.4477 11.4477 14 12 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H12C11.4477 16 11 15.5523 11 15Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
