import { SvgProps } from "@design-system/Icon/types";

export const Quote = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M13.5858 5.58554C13.9609 5.21047 14.4696 4.99976 15 4.99976H18C18.5304 4.99976 19.0391 5.21047 19.4142 5.58554C19.7893 5.96062 20 6.46932 20 6.99976V12.9998C20 14.5023 19.6219 15.8234 18.781 16.8745C17.9426 17.9224 16.7268 18.5987 15.2426 18.9699C14.7068 19.1039 14.1639 18.7782 14.0299 18.2424C13.8959 17.7066 14.2216 17.1636 14.7574 17.0296C15.9402 16.7338 16.7244 16.2436 17.2193 15.625C17.7116 15.0097 18 14.1642 18 12.9998V11.9998H15C14.4696 11.9998 13.9609 11.789 13.5858 11.414C13.2107 11.0389 13 10.5302 13 9.99976V6.99976C13 6.46932 13.2107 5.96062 13.5858 5.58554ZM18 9.99976V6.99976L15 6.99976V9.99976H18Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4.58579 5.58554C4.96086 5.21047 5.46957 4.99976 6 4.99976L9 4.99976C9.53043 4.99976 10.0391 5.21047 10.4142 5.58554C10.7893 5.96062 11 6.46932 11 6.99976V12.9998C11 14.5023 10.6219 15.8234 9.78098 16.8745C8.94259 17.9224 7.72684 18.5987 6.24262 18.9699C5.70684 19.1039 5.16387 18.7782 5.02988 18.2424C4.89588 17.7066 5.2216 17.1636 5.75738 17.0296C6.94016 16.7338 7.72441 16.2436 8.21927 15.625C8.7116 15.0097 9 14.1642 9 12.9998V11.9998H6C5.46957 11.9998 4.96086 11.789 4.58579 11.414C4.21071 11.0389 4 10.5302 4 9.99976V6.99976C4 6.46932 4.21071 5.96061 4.58579 5.58554ZM9 9.99976L9 6.99976H6L6 9.99976H9Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
