import { SvgProps } from "@design-system/Icon/types";

export const Sync = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M16.5752 4.23531C14.8468 3.23682 12.8381 2.83429 10.8585 3.08973C8.87885 3.34516 7.03812 4.24439 5.61984 5.6489C4.20155 7.0534 3.28439 8.88526 3.00964 10.8623C2.93362 11.4093 3.31544 11.9144 3.86247 11.9904C4.4095 12.0665 4.91458 11.6846 4.9906 11.1376C5.20497 9.59508 5.92056 8.16582 7.02713 7.07C8.13371 5.97417 9.56988 5.27258 11.1144 5.07328C12.659 4.87399 14.2262 5.18805 15.5747 5.96709C16.462 6.47968 17.2222 7.17486 17.809 7.99995H16.0001C15.4478 7.99995 15.0001 8.44767 15.0001 8.99995C15.0001 9.55224 15.4478 9.99995 16.0001 9.99995H19.4778C19.4929 10.0003 19.5081 10.0003 19.5233 9.99995H20.0001C20.5524 9.99995 21.0001 9.55224 21.0001 8.99995V4.99995C21.0001 4.44767 20.5524 3.99995 20.0001 3.99995C19.4478 3.99995 19.0001 4.44767 19.0001 4.99995V6.27281C18.3216 5.46243 17.5025 4.77106 16.5752 4.23531Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M20.1374 12.0095C19.5904 11.9335 19.0853 12.3153 19.0093 12.8624C18.7949 14.4049 18.0793 15.8341 16.9727 16.93C15.8662 18.0258 14.43 18.7274 12.8855 18.9267C11.3409 19.126 9.77368 18.8119 8.42519 18.0329C7.53791 17.5203 6.77772 16.8251 6.19091 16H7.99975C8.55204 16 8.99975 15.5523 8.99975 15C8.99975 14.4477 8.55204 14 7.99975 14H4.52212C4.50697 13.9997 4.49179 13.9997 4.47658 14H3.99976C3.44747 14 2.99976 14.4477 2.99976 15V19C2.99976 19.5523 3.44747 20 3.99976 20C4.55204 20 4.99976 19.5523 4.99976 19V17.7271C5.67831 18.5375 6.49736 19.2289 7.42472 19.7646C9.15307 20.7631 11.1618 21.1657 13.1414 20.9102C15.121 20.6548 16.9618 19.7556 18.38 18.3511C19.7983 16.9466 20.7155 15.1147 20.9902 13.1376C21.0663 12.5906 20.6844 12.0855 20.1374 12.0095Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
