import { useContext } from "react";
import { System } from "@design-system/index";
import { TypeGenericActionGenericModal } from "../types";

export const useGenericModal = <DataModal,>() => {
    const context = useContext(System.Context.GenericModal.Context);

    if (!context) {
        throw new Error(
            "useGenericModal must be used within a System.Context.GenericModal.Provider"
        );
    }

    function openModal(payload: {
        component: React.ReactNode;
        data?: DataModal;
        size?: "sm" | "default" | "md" | "lg" | "xl" | "full";
        withPadding?: boolean;
        hideOverflow?: boolean;
    }) {
        context.dispatch({
            type: TypeGenericActionGenericModal.OPEN_MODAL,
            payload: payload,
        });
    }

    function closeModal() {
        context.dispatch({
            type: TypeGenericActionGenericModal.CLOSE_MODAL,
        });
    }

    return {
        state: context.state,
        openModal: openModal,
        closeModal: closeModal,
    };
};
