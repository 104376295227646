import { cn } from "@helpers/utils";
import { PropsWithChildren } from "react";

interface WhatsNewCardProps {
    className?: string;
    children?: React.ReactNode;
}

export const WhatsNewCard = ({ className, children }: WhatsNewCardProps) => {
    return (
        <div
            className={cn(
                "flex flex-col p-10 gap-6 rounded-lg border-2 bg-primary-lightest border-primary-lighter",
                className
            )}
        >
            <span className="text-primary-base text-title-h4">What's New?</span>
            <div className="flex flex-col gap-2">{children}</div>
        </div>
    );
};

export const WhatsNewCardTags = ({ children }: PropsWithChildren) => (
    <div className="flex gap-2">{children}</div>
);

export const WhatsNewCardContent = ({ children }: PropsWithChildren) => (
    <div className="flex flex-col gap-2">{children}</div>
);

export const WhatsNewCardTitle = ({ children }: PropsWithChildren) => (
    <span className="text-main-grey-600 text-title-h3">{children}</span>
);

export const WhatsNewCardDescription = ({ children }: PropsWithChildren) => (
    <p className="text-body-base text-main-grey-500">{children}</p>
);
