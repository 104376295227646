import { SvgProps } from "@design-system/Icon/types";

export const HeartFilled = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        d="M19.5001 12.5721L12.0001 20.0001L4.50006 12.5721C4.00536 12.0907 3.6157 11.5121 3.3556 10.8727C3.09551 10.2333 2.97062 9.54701 2.98879 8.85699C3.00697 8.16697 3.16782 7.48819 3.46121 6.86339C3.75461 6.23859 4.17419 5.68131 4.69354 5.22663C5.21289 4.77196 5.82076 4.42974 6.47887 4.22154C7.13697 4.01333 7.83106 3.94364 8.51743 4.01686C9.20379 4.09007 9.86756 4.30461 10.4669 4.64696C11.0663 4.98931 11.5883 5.45205 12.0001 6.00605C12.4136 5.45608 12.9362 4.99738 13.5352 4.65866C14.1341 4.31994 14.7966 4.1085 15.481 4.03757C16.1654 3.96665 16.8571 4.03775 17.5128 4.24645C18.1685 4.45514 18.7741 4.79693 19.2916 5.25042C19.8091 5.70391 20.2275 6.25934 20.5205 6.88195C20.8135 7.50456 20.9748 8.18094 20.9944 8.86876C21.0139 9.55659 20.8913 10.241 20.6342 10.8793C20.3771 11.5176 19.991 12.0959 19.5001 12.5781"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8.41135 5.01121C7.86226 4.95264 7.30699 5.00839 6.78051 5.17496C6.25402 5.34152 5.76773 5.6153 5.35225 5.97904C4.93677 6.34278 4.6011 6.7886 4.36638 7.28844C4.13167 7.78828 4.00299 8.33131 3.98845 8.88332C3.97391 9.43533 4.07382 9.98438 4.2819 10.4959C4.48997 11.0074 4.8017 11.4703 5.19746 11.8554L5.20377 11.8615L12.0001 18.5926L18.7964 11.8615C18.8171 11.841 18.8385 11.8216 18.8605 11.8033C19.2242 11.4302 19.5117 10.9894 19.7066 10.5056C19.9123 9.99503 20.0104 9.44746 19.9948 8.8972C19.9791 8.34694 19.8501 7.80583 19.6157 7.30775C19.3813 6.80966 19.0466 6.36531 18.6326 6.00252C18.2185 5.63973 17.7341 5.3663 17.2095 5.19935C16.685 5.03239 16.1316 4.9755 15.5841 5.03225C15.0365 5.08899 14.5066 5.25814 14.0274 5.52912C13.5482 5.80009 13.1301 6.16705 12.7993 6.60703C12.6098 6.85905 12.3126 7.00693 11.9973 7.00605C11.682 7.00517 11.3855 6.85564 11.1975 6.60257C10.8681 6.15937 10.4505 5.78918 9.97096 5.5153C9.49146 5.24142 8.96044 5.06979 8.41135 5.01121ZM20.1347 13.3509L12.7037 20.7106C12.314 21.0965 11.6861 21.0965 11.2964 20.7106L3.79935 13.2855C3.20729 12.7085 2.74085 12.0153 2.42931 11.2495C2.1172 10.4822 1.96733 9.65868 1.98914 8.83066C2.01095 8.00264 2.20397 7.1881 2.55605 6.43834C2.90812 5.68858 3.41162 5.01984 4.03484 4.47423C4.65806 3.92862 5.3875 3.51796 6.17723 3.26811C6.96696 3.01826 7.79986 2.93464 8.6235 3.0225C9.44713 3.11036 10.2437 3.3678 10.9629 3.77862C11.337 3.99227 11.6859 4.24499 12.0042 4.53187C12.3221 4.24846 12.6702 3.99898 13.0429 3.7882C13.7617 3.38174 14.5566 3.12802 15.3779 3.0429C16.1992 2.95779 17.0293 3.04312 17.8161 3.29355C18.6029 3.54398 19.3296 3.95412 19.9507 4.49831C20.5717 5.0425 21.0737 5.70902 21.4253 6.45615C21.7769 7.20328 21.9705 8.01494 21.994 8.84033C22.0174 9.66572 21.8703 10.4871 21.5618 11.253C21.2532 12.0189 20.7899 12.7129 20.2008 13.2915C20.1794 13.3124 20.1574 13.3323 20.1347 13.3509Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
