import classNames from "classnames";
import { ButtonOrLinkProps } from "@design-system/Button/Default";
import { System } from "@design-system/index";

interface Props extends ButtonOrLinkProps {
    isClickable?: boolean;
}

export const StepPreviousButton = ({
    children,
    className = "",
    isClickable = false,
    href,
    ...rest
}: Props) => {
    const classes = classNames(
        "border-2 bg-indigo-600 rounded-full border-indigo-600 relative",
        className
    );
    return (
        <>
            {isClickable ? (
                <System.Button.Primary
                    iconOnly
                    size="large"
                    href={href}
                    className={classes}
                    {...rest}
                >
                    <System.Svg.Check className="w-6 h-6" />
                    <System.Strong
                        size="small"
                        className="sr-only absolute top-full pt-2 left-1/2 -translate-x-1/2 whitespace-nowrap"
                    >
                        {children}
                    </System.Strong>
                </System.Button.Primary>
            ) : (
                <span className={classes}>
                    <System.IconBullet icon="Check" />
                    <System.Strong
                        size="small"
                        className="sr-only absolute top-full pt-2 left-1/2 -translate-x-1/2 whitespace-nowrap"
                    >
                        {children}
                    </System.Strong>
                </span>
            )}
        </>
    );
};
