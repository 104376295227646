import { System } from "@design-system/index";
import { TableColumn } from "@design-system/Table/types";
import { useTable } from "@design-system/Table/hooks/useTable";
import { getColumnsClassnames } from "@design-system/Table/getColumnsClassnames";

interface Props {
    columns: Array<TableColumn>;
    columnsClassname?: string;
}

export const TableHeaderWithColumns = ({
    columns,
    columnsClassname = "",
}: Props) => {
    const { state } = useTable();

    const {
        rowKeys,
        withCheckedChildren,
        rowChildrenKeys,
        expandedRows,
        checkedRows,
    } = state;

    if (columns.length === 0) {
        return null;
    }

    const size = withCheckedChildren ? rowChildrenKeys.size : rowKeys.size;

    const classes = getColumnsClassnames(columns, columnsClassname);

    return (
        <System.Table.Header
            isChecked={checkedRows.size > 0}
            isIndeterminate={
                checkedRows.size !== 0 && checkedRows.size !== size
            }
            isAllExpanded={expandedRows.size === rowKeys.size}
            className={classes}
        >
            <>
                {columns.map((column: TableColumn) => (
                    <System.Text
                        key={column.key}
                        className={column.width}
                        as="span"
                    >
                        {column.name}
                    </System.Text>
                ))}
            </>
        </System.Table.Header>
    );
};
