import { SystemSvgTypes } from "@design-system/Icon/types";
import { VariantProps } from "class-variance-authority";
import { System } from "..";
import { tagVariants } from "./Tag.variants";

interface TagIconProps {
    icon: SystemSvgTypes;
    size: VariantProps<typeof tagVariants>["size"];
    className?: string;
}

export const TagIcon = ({ icon, size, className }: TagIconProps) => {
    const Icon = System.Svg[icon];
    const iconSize = getIconSize(size);
    return <Icon size={iconSize} className={className} />;
};

const getIconSize = (size: VariantProps<typeof tagVariants>["size"]) => {
    if (size === "sm") {
        return 13;
    }
    if (size === "xs") {
        return 11;
    }
    return 16;
};
