import { SvgProps } from "@design-system/Icon/types";

export const Feather = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M14.3827 4.07612C14.7564 4.2309 15 4.59554 15 5V9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H14.4142L12.4142 13H15C15.5523 13 16 13.4477 16 14C16 14.5523 15.5523 15 15 15H10.4142L8.41421 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H6.41421L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L5 17.5858V13C5 12.7348 5.10536 12.4804 5.29289 12.2929L13.2929 4.29289C13.5789 4.0069 14.009 3.92134 14.3827 4.07612ZM7 15.5858V13.4142L9 11.4142V13.5858L7 15.5858ZM11 11.5858V9.41421L13 7.41421V9.58579L11 11.5858Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M16.483 3C17.0758 2.99974 17.6629 3.11623 18.2107 3.34282C18.7587 3.56949 19.2567 3.9019 19.6762 4.32105C20.0957 4.7402 20.4286 5.2379 20.6557 5.7857C20.8828 6.33351 20.9998 6.92069 21 7.51372C21 8.73728 20.5414 9.8752 19.7065 10.7077C19.7063 10.7079 19.7066 10.7076 19.7065 10.7077L11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071C9.90237 18.3166 9.90237 17.6834 10.2929 17.2929L18.294 9.29178C18.7347 8.85243 18.9999 8.23237 19 7.51428M16.483 3C15.2563 3.00014 14.123 3.46666 13.2946 4.29122C12.9031 4.68082 12.9016 5.31399 13.2912 5.70543C13.6808 6.09688 14.314 6.09838 14.7054 5.70878C15.1488 5.26747 15.77 5 16.483 5C16.8133 4.99981 17.141 5.0647 17.4463 5.19096C17.7515 5.31723 18.0289 5.50238 18.2626 5.73587C18.4963 5.96935 18.6817 6.24658 18.8082 6.55172C18.9347 6.85678 18.9999 7.18405 19 7.51428"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
