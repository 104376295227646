import { SvgProps } from "@design-system/Icon/types";

export const Trustpilot = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        fill="#00B67A"
                        d="M23.4661 9.3783H14.5054L11.7376 1L8.96 9.379L0 9.3699L7.2569 14.5527L4.4807 22.9219L11.7376 17.7475L18.9861 22.9219L16.2183 14.5527L23.4661 9.3783Z"
                    />
                </g>
                <g>
                    <path
                        fill="#005128"
                        d="M16.8405 16.4484L16.2174 14.5535L11.7375 17.7475L16.8405 16.4484Z"
                    />
                </g>
            </g>
        </svg>
    );
};
