import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isEmpty, isNil } from "lodash";
import { System } from "@design-system/index";
import { useGenericPagination } from "@design-system/Context/GenericPagination/hooks/usePagination";

interface Props {
    className?: string;
}

export function FullPagination({ className }: Props) {
    const { state, getStartPage, getPageLimitNumber, setPage, setPerPage } =
        useGenericPagination();

    const classes = classNames(
        "w-full flex items-center justify-between",
        className
    );

    return (
        <div className={classes}>
            <System.Text>
                Showing{" "}
                <span className="font-semibold text-gray-900">
                    {getStartPage()}
                </span>{" "}
                to{" "}
                <span className="font-semibold text-gray-900">
                    {getPageLimitNumber()}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900">
                    {state.total}
                </span>{" "}
                results
            </System.Text>

            <div className="flex items-center gap-8">
                {state.maxPage !== 1 && (
                    <nav
                        className="relative z-0 inline-flex gap-2"
                        aria-label="Pagination"
                    >
                        {state.maxPage !== 1 && state.page !== 1 && (
                            <System.Button.Outlined
                                onClick={() => {
                                    if (state.page === 1) {
                                        return;
                                    }
                                    setPage(state.page - 1);
                                }}
                            >
                                <ChevronLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                                Previous Page
                            </System.Button.Outlined>
                        )}

                        {state.maxPage !== state.page && (
                            <System.Button.Outlined
                                onClick={() => {
                                    if (state.page === state.maxPage) {
                                        return;
                                    }
                                    setPage(state.page + 1);
                                }}
                            >
                                Next Page{" "}
                                <ChevronRightIcon className="h-5 w-5" />
                            </System.Button.Outlined>
                        )}
                    </nav>
                )}
                {!isNil(state.optionsPerPage) &&
                    !isEmpty(state.optionsPerPage) && (
                        <select
                            id="perPage"
                            name="perPage"
                            value={state.perPage}
                            onChange={(e) => setPerPage(Number(e.target.value))}
                            className="cursor-pointer block pl-3 pr-10 py-2 rounded-full border-gray-200 focus-visible:border-gray-200 focus-visible:outline-none focus-visible:ring-indigo-600 focus-visible:ring-2 focus-visible:ring-offset-2 sm:text-sm text-base transition"
                        >
                            {state.optionsPerPage.map((item) => {
                                return (
                                    <option value={item.value} key={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </select>
                    )}
            </div>
        </div>
    );
}
