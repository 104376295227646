import { SVGNewUmbrella } from "../../../ui/Icons/NewUmbrella";

export const SuspenseFallbackSecurity = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center flex-col">
            <SVGNewUmbrella className="w-48" />
            <p className="text-2xl mt-2">Loading in progress...</p>
        </div>
    );
};
