import { SvgProps } from "@design-system/Icon/types";

export const CameraQuestion = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7.58579 3.58579C7.96086 3.21071 8.46957 3 9 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5C17 5.26522 17.1054 5.51957 17.2929 5.70711C17.4804 5.89464 17.7348 6 18 6H19C19.7957 6 20.5587 6.31607 21.1213 6.87868C21.6839 7.44129 22 8.20435 22 9V11.5C22 12.0523 21.5523 12.5 21 12.5C20.4477 12.5 20 12.0523 20 11.5V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8H18C17.2044 8 16.4413 7.68393 15.8787 7.12132C15.3161 6.55871 15 5.79565 15 5L9 5C9 5.79565 8.68393 6.55871 8.12132 7.12132C7.55871 7.68393 6.79565 8 6 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H15C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21H5C4.20435 21 3.44129 20.6839 2.87868 20.1213C2.31607 19.5587 2 18.7956 2 18V9C2 8.20435 2.31607 7.44129 2.87868 6.87868C3.44129 6.31607 4.20435 6 5 6H6C6.26522 6 6.51957 5.89464 6.70711 5.70711C6.89464 5.51957 7 5.26522 7 5C7 4.46957 7.21071 3.96086 7.58579 3.58579Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M10.1915 9.4329C10.9551 9.04572 11.8228 8.9138 12.6671 9.05653C13.5113 9.19925 14.2874 9.6091 14.8814 10.2258C15.4753 10.8425 15.8557 11.6335 15.9666 12.4825C16.0381 13.0301 15.6522 13.532 15.1045 13.6036C14.5569 13.6751 14.055 13.2892 13.9834 12.7415C13.928 12.317 13.7378 11.9215 13.4408 11.6132C13.1438 11.3048 12.7558 11.0999 12.3337 11.0285C11.9116 10.9572 11.4777 11.0231 11.0959 11.2167C10.714 11.4103 10.4044 11.7213 10.2125 12.104C10.0206 12.4867 9.95652 12.9208 10.0297 13.3426C10.103 13.7644 10.3096 14.1516 10.6192 14.4472C10.9289 14.7428 11.3252 14.9312 11.7499 14.9848C12.1747 15.0384 12.6054 14.9543 12.9788 14.7449C13.4604 14.4747 14.0699 14.6461 14.3401 15.1278C14.6103 15.6094 14.4389 16.2189 13.9572 16.4891C13.2105 16.908 12.3491 17.0763 11.4996 16.9691C10.6501 16.8619 9.85748 16.485 9.23818 15.8938C8.61889 15.3026 8.20564 14.5282 8.0592 13.6847C7.91277 12.8411 8.04088 11.9728 8.42471 11.2074C8.80853 10.4421 9.4278 9.82008 10.1915 9.4329Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M19 21C19.5523 21 20 21.4477 20 22V22.01C20 22.5623 19.5523 23.01 19 23.01C18.4477 23.01 18 22.5623 18 22.01V22C18 21.4477 18.4477 21 19 21Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M18.4266 14.0582C19.0863 13.9262 19.7712 14.0213 20.3699 14.328L20.3735 14.3298C20.9705 14.6387 21.4466 15.1388 21.7256 15.7503C22.0046 16.3618 22.0705 17.0491 21.9126 17.7024C21.7547 18.3557 21.3822 18.9372 20.8547 19.3537C20.3273 19.7703 19.6753 19.9978 19.0032 20C18.4509 20.0018 18.0018 19.5555 18 19.0032C17.9982 18.4509 18.4445 18.0018 18.9968 18C19.2213 17.9993 19.439 17.9233 19.6152 17.7842C19.7914 17.645 19.9158 17.4508 19.9685 17.2326C20.0213 17.0144 19.9993 16.7848 19.9061 16.5806C19.8132 16.377 19.6548 16.2103 19.4563 16.1071C19.2598 16.007 19.0353 15.976 18.819 16.0193C18.6021 16.0627 18.4062 16.1782 18.2633 16.3471C17.9064 16.7686 17.2755 16.8211 16.8539 16.4643C16.4324 16.1074 16.3799 15.4765 16.7367 15.0549C17.1714 14.5414 17.767 14.1901 18.4266 14.0582Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
