import { SvgProps } from "@design-system/Icon/types";

export const LoaderUdisabled = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <circle cx="12" cy="12" r="9" stroke="currentColor" />
            </g>
        </svg>
    );
};
