import { System } from "@design-system/index";
import { UptimeTimelineContainer } from "../UptimeTimelineContainer";
import { UptimeStatus } from "@design-system/Uptime/UptimeStatus";

export interface TimelineItemStatus {
    status: UptimeStatus;
    date: string;
}

interface Props {
    data: TimelineItemStatus;
}

export function UptimeTimelineStatus({ data }: Props) {

    const { status, date } = data;

    switch (status) {

        case UptimeStatus.UP:
            return (
                <UptimeTimelineContainer>
                    <System.Uptime.Status className="px-1.5 bg-white ring-white ring-4" status={status} />
                    <System.Text>Your site is <System.Strong>operational</System.Strong> since</System.Text>
                    <System.Text className="whitespace-nowrap">{date}</System.Text>
                </UptimeTimelineContainer>
            );

        case UptimeStatus.DOWN:
            return (
                <UptimeTimelineContainer>
                    <System.Uptime.Status className="px-1.5 bg-white ring-white ring-4" status={status} />
                    <System.Text>Your site is <System.Strong>down</System.Strong> since</System.Text>
                    <System.Text className="whitespace-nowrap">{date}</System.Text>
                </UptimeTimelineContainer>
            );

        case UptimeStatus.PAUSED:
            return (
                <UptimeTimelineContainer>
                    <System.Uptime.Status className="px-1.5 bg-white ring-white ring-4" status={status} />
                    <System.Text>Your site is paused since</System.Text>
                    <System.Text className="whitespace-nowrap">{date}</System.Text>
                </UptimeTimelineContainer>
            );

    }

    return null;
}
