import classNames from "classnames";
import { System } from "@design-system/index";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    label: string;
    type: string;
    name: string;
    className?: string;
    required?: boolean;
}

export const FormField: React.FC<Props> = ({
    className = "",
    label,
    required,
    type,
    name,
    ...rest
}: Props) => {
    const classes = classNames(
        "flex",
        {
            "sr-only": type === "hidden",
            "flex-col gap-1": type !== "checkbox" && type !== "radio",
            "items-center": type === "checkbox" || type === "radio",
        },
        className
    );

    return (
        <div className={classes}>
            {type !== "hidden" && type !== "checkbox" && type !== "radio" && (
                <System.Form.Label
                    required={required}
                    htmlFor={name}
                    className="ml-4 inline-block"
                >
                    {label}
                </System.Form.Label>
            )}
            <System.Form.Input id={name} name={name} type={type} {...rest} />
            {(type === "checkbox" || type === "radio") && (
                <System.Form.Label htmlFor={name} className="ml-4 inline-block">
                    {label}
                </System.Form.Label>
            )}
        </div>
    );
};
