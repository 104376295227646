import { SvgProps } from "@design-system/Icon/types";

export const Image = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M14 8C14 7.44772 14.4477 7 15 7H15.01C15.5623 7 16.01 7.44772 16.01 8C16.01 8.55229 15.5623 9 15.01 9H15C14.4477 9 14 8.55229 14 8Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6ZM3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6C2 4.93913 2.42143 3.92172 3.17157 3.17157Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8.69967 11.7145L3.70711 16.7071C3.31658 17.0976 2.68342 17.0976 2.29289 16.7071C1.90237 16.3165 1.90237 15.6834 2.29289 15.2928L7.29289 10.2928L7.30661 10.2794C7.92076 9.6884 8.67726 9.3302 9.5 9.3302C10.3227 9.3302 11.0792 9.6884 11.6934 10.2794L11.7071 10.2928L16.7071 15.2928C17.0976 15.6834 17.0976 16.3165 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L10.3003 11.7145C9.98904 11.4173 9.71184 11.3302 9.5 11.3302C9.28817 11.3302 9.01097 11.4173 8.69967 11.7145Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M15.6997 13.7145L14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071C12.9024 14.3165 12.9024 13.6834 13.2929 13.2928L14.2929 12.2928L14.3066 12.2794C14.9208 11.6884 15.6773 11.3302 16.5 11.3302C17.3227 11.3302 18.0792 11.6884 18.6934 12.2794L18.7071 12.2928L21.7071 15.2928C22.0976 15.6834 22.0976 16.3165 21.7071 16.7071C21.3166 17.0976 20.6834 17.0976 20.2929 16.7071L17.3003 13.7145C16.989 13.4173 16.7118 13.3302 16.5 13.3302C16.2882 13.3302 16.011 13.4173 15.6997 13.7145Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
