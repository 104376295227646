import Config from "../environments";
import { createContext, useContext, useState } from "react";
import { isEmpty } from "lodash";

type PageDependenciesContextType = {
    values: {
        head: {
            title: string;
        };
    };
    actions: {
        setPageProperties?: Function;
    };
};

export const PageDependenciesContext =
    createContext<PageDependenciesContextType>({
        values: {
            head: {
                title: Config.app_title,
            },
        },
        actions: {},
    });

export const PageDependenciesContextProvider = ({ children }: any) => {
    const [pageProperties, setPageProperties] = useState({
        head: {
            title: Config.app_title,
        },
    });

    return (
        <PageDependenciesContext.Provider
            value={{
                values: {
                    ...pageProperties,
                },
                actions: {
                    setPageProperties,
                },
            }}
        >
            {children}
        </PageDependenciesContext.Provider>
    );
};

export const useHeadTitle = () => {
    const { values, actions } = useContext(PageDependenciesContext);

    const setTitle = (value) => {
        let newTitle = Config.app_title;
        if (!isEmpty(value)) {
            newTitle = `${value} | ${Config.app_title}`;
        }

        actions.setPageProperties({
            ...values,
            head: {
                ...values.head,
                title: newTitle,
            },
        });
    };

    return {
        title: values.head.title,
        setTitle,
    };
};

export default { PageDependenciesContext, PageDependenciesContextProvider };
