import { useTable } from "@design-system/Table/hooks/useTable";
import { System } from "@design-system/index";
import classNames from "classnames";

interface Props {
    className?: string;
}

export const BulkActionsCheckedRows = ({ className = "" }: Props) => {
    const { state, clearCheckedRows, checkAllRows } = useTable();
    const { checkedRows, rowKeys } = state;
    const classes = classNames("text-sm text-gray-600 flex-1", className);

    return (
        <System.Content.BulkActions.CheckedRows
            checkAllRows={checkAllRows}
            checkedRows={checkedRows}
            clearCheckedRows={clearCheckedRows}
            rowKeys={rowKeys}
            className={classes}
        />
    );
};
