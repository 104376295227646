import { clone, get, isEmpty } from "lodash";
import { createContext, useEffect, useState } from "react";

type NotificationContextType = {
    values: {
        show: boolean;
        data: {
            title: string;
            message: string;
            type: TypeNotification;
            timeout?: number;
        };
    };
    actions: {
        setShow: Function;
        setData: Function;
    };
};

export enum TypeNotification {
    SUCCESS,
    ERROR,
}

export const NotificationContext = createContext<NotificationContextType>({
    values: {
        show: false,
        data: {
            title: "",
            message: "",
            type: TypeNotification.SUCCESS,
            timeout: 12000,
        },
    },
    actions: {
        setShow: () => {},
        setData: () => {},
    },
});

const DEFAULT_DATA = { title: "", message: "", type: TypeNotification.SUCCESS };

export const NotificationContextProvider = ({ values, children }: any) => {
    const [show, setShow] = useState(get(values, "show", false));
    const [data, setData] = useState<{
        title: string;
        message: string;
        type: TypeNotification;
        timeout?: number;
    }>(get(values, "data", DEFAULT_DATA));

    useEffect(() => {
        if (isEmpty(data.title) && isEmpty(data.message)) {
            return;
        }

        setShow(true);
        setTimeout(() => {
            setShow(false);
            setData(clone(DEFAULT_DATA));
        }, data.timeout || 12000);
    }, [data]);

    return (
        <NotificationContext.Provider
            value={{
                values: {
                    show,
                    data,
                },
                actions: {
                    setShow,
                    setData,
                },
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { NotificationContext, NotificationContextProvider };
