import qs from "query-string";
import { first, get, isNil, isObject } from "lodash";

export const fetcher = () =>
    async function (...args: any) {
        const firstElement = first(args);

        const url = isObject(firstElement)
            ? get(firstElement, "url", firstElement)
            : firstElement;

        let query = null;
        if (
            isObject(firstElement) &&
            !isNil(get(firstElement, "queryParams", ""))
        ) {
            query = get(firstElement, "queryParams", "");
        }

        const path = qs.stringifyUrl({
            url: url,
            query: query,
        });

        const res = await fetch(path, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const total = res.headers.get("X-Total");
        try {
            const data = await res.json();
            if (isNil(total)) {
                return data;
            }

            return {
                total: total,
                items: data,
            };
        } catch (error) {
            return null;
        }
    };

export default fetcher;
