import { System } from "@design-system/index";
import { getThemeFavicon } from "@/Helpers/getThemeFavicon";
interface Props {
    slug: string;
    className?: string;
    version?: string;
}

export const ThemeFavicon = ({
    slug,
    version = "latest",
    className = "",
}: Props) => {
    return (
        <System.Badges.FromWordPressFavicon
            imageUrl={getThemeFavicon({ theme: slug, version: version })}
            className={className}
            defaultIcon={<System.Svg.Theme className="w-5 h-5" />}
        />
    );
};
