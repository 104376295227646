import { SvgProps } from "@design-system/Icon/types";

export const WordPress = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    strokeWidth={strokeWidth}
                    fill="currentColor"
                    d="M20.6783 7.25507C21.4503 8.66364 21.8896 10.2801 21.8896 11.9996C21.8896 15.6482 19.9123 18.8336 16.9721 20.5479L19.9927 11.8143C20.5569 10.4034 20.7448 9.27522 20.7448 8.27207C20.7448 7.90796 20.7208 7.57005 20.6783 7.25507ZM13.3629 7.36274C13.9583 7.33143 14.4948 7.2688 14.4948 7.2688C15.0277 7.20571 14.9648 6.42256 14.4317 6.45376C14.4317 6.45376 12.8298 6.57947 11.7957 6.57947C10.8239 6.57947 9.19103 6.45376 9.19103 6.45376C8.65767 6.42256 8.59516 7.23726 9.12829 7.2688C9.12829 7.2688 9.63278 7.33143 10.1657 7.36274L11.7064 11.5845L9.54175 18.0754L5.94048 7.36274C6.53635 7.33143 7.07227 7.2688 7.07227 7.2688C7.60481 7.20571 7.54195 6.42256 7.00906 6.45376C7.00906 6.45376 5.40747 6.57947 4.37324 6.57947C4.18769 6.57947 3.96897 6.57493 3.73674 6.56748C5.50514 3.8828 8.54464 2.10989 11.9999 2.10989C14.5745 2.10989 16.9189 3.0943 18.6783 4.70646C18.6357 4.7039 18.5942 4.69854 18.5503 4.69854C17.5788 4.69854 16.8894 5.54478 16.8894 6.45376C16.8894 7.2688 17.3597 7.95836 17.8609 8.77329C18.237 9.43201 18.6763 10.2781 18.6763 11.5008C18.6763 12.3472 18.3508 13.3295 17.9238 14.6979L16.937 17.9938L13.3629 7.36274ZM11.9999 21.8901C11.0291 21.8901 10.0922 21.7478 9.20593 21.4871L12.1736 12.8647L15.2134 21.1931C15.2334 21.242 15.2579 21.287 15.2841 21.3294C14.2562 21.6913 13.1516 21.8901 11.9999 21.8901ZM2.11013 11.9996C2.11013 10.5658 2.41767 9.20468 2.96651 7.97478L7.6842 20.9002C4.38476 19.2974 2.11013 15.9142 2.11013 11.9996ZM11.9999 1C5.93455 1 1 5.93438 1 11.9996C1 18.065 5.93455 23 11.9999 23C18.0652 23 23 18.065 23 11.9996C23 5.93438 18.0652 1 11.9999 1Z"
                />
            </g>
        </svg>
    );
};
