import { SvgProps } from "@design-system/Icon/types";

export const Star = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeWidth={strokeWidth}
                d="M11.9998 17.75L5.82784 20.995L7.00684 14.122L2.00684 9.25501L8.90684 8.25501L11.9928 2.00201L15.0788 8.25501L21.9788 9.25501L16.9788 14.122L18.1578 20.995L11.9998 17.75Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
