import { SvgProps } from "@design-system/Icon/types";

export const CheckCircle = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <circle cx="12" cy="12" r="10" fill="currentColor" />
                <path
                    strokeWidth={strokeWidth}
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M17.4713 8.19526C17.7317 8.45561 17.7317 8.87772 17.4713 9.13807L10.8047 15.8047C10.5443 16.0651 10.1222 16.0651 9.86185 15.8047L6.52851 12.4714C6.26816 12.2111 6.26816 11.7889 6.52851 11.5286C6.78886 11.2682 7.21097 11.2682 7.47132 11.5286L10.3333 14.3905L16.5285 8.19526C16.7889 7.93491 17.211 7.93491 17.4713 8.19526Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
};
