import { get } from "lodash";
import { createContext, useState } from "react";

type SlideOverUserNotificationsContextType = {
    values: {
        open: boolean;
        page: number;
    };
    actions: {
        setOpen: Function;
        setPage: Function;
    };
};

export const SlideOverUserNotificationsContext =
    createContext<SlideOverUserNotificationsContextType>({
        values: {
            open: false,
            page: 1,
        },
        actions: {
            setOpen: () => {},
            setPage: () => {},
        },
    });

export const SlideOverUserNotificationsContextProvider = ({
    values,
    children,
}: any) => {
    const [open, setOpen] = useState(get(values, "open", false));
    const [page, setPage] = useState(get(values, "page", 1));

    return (
        <SlideOverUserNotificationsContext.Provider
            value={{
                values: {
                    open,
                    page,
                },
                actions: {
                    setOpen,
                    setPage,
                },
            }}
        >
            {children}
        </SlideOverUserNotificationsContext.Provider>
    );
};

export default {
    SlideOverUserNotificationsContext,
    SlideOverUserNotificationsContextProvider,
};
