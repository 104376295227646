import { SvgProps } from "@design-system/Icon/types";

export const Database = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M5.99202 2.94235C7.63443 2.32645 9.79501 2 12 2C14.205 2 16.3656 2.32645 18.008 2.94235C18.8276 3.24972 19.5385 3.63656 20.0622 4.11062C20.5855 4.58436 21 5.22304 21 6C21 6.77696 20.5855 7.41564 20.0622 7.88938C19.5385 8.36344 18.8276 8.75028 18.008 9.05765C16.3656 9.67355 14.205 10 12 10C9.79501 10 7.63443 9.67355 5.99202 9.05765C5.17237 8.75028 4.46146 8.36344 3.93782 7.88938C3.41454 7.41564 3 6.77696 3 6C3 5.22304 3.41454 4.58436 3.93782 4.11062C4.46146 3.63656 5.17237 3.24972 5.99202 2.94235ZM12 4C9.96153 4 8.05244 4.3057 6.69427 4.81501C6.01363 5.07025 5.55297 5.34625 5.28011 5.59328C5.00689 5.84063 5 5.98131 5 6C5 6.01869 5.00689 6.15937 5.28011 6.40672C5.55297 6.65376 6.01363 6.92975 6.69427 7.18499C8.05244 7.69431 9.96153 8 12 8C14.0385 8 15.9476 7.69431 17.3057 7.18499C17.9864 6.92975 18.447 6.65376 18.7199 6.40672C18.9931 6.15937 19 6.01869 19 6C19 5.98131 18.9931 5.84063 18.7199 5.59328C18.447 5.34625 17.9864 5.07025 17.3057 4.81501C15.9476 4.3057 14.0385 4 12 4Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4 5C4.55228 5 5 5.44772 5 6V12C5 12.0187 5.00689 12.1594 5.28011 12.4067C5.55297 12.6538 6.01363 12.9298 6.69427 13.185C8.05244 13.6943 9.96153 14 12 14C14.0385 14 15.9476 13.6943 17.3057 13.185C17.9864 12.9298 18.447 12.6538 18.7199 12.4067C18.9931 12.1594 19 12.0187 19 12V6C19 5.44772 19.4477 5 20 5C20.5523 5 21 5.44772 21 6V12C21 12.777 20.5855 13.4156 20.0622 13.8894C19.5385 14.3634 18.8276 14.7503 18.008 15.0576C16.3656 15.6736 14.205 16 12 16C9.79501 16 7.63443 15.6736 5.99202 15.0576C5.17237 14.7503 4.46146 14.3634 3.93782 13.8894C3.41454 13.4156 3 12.777 3 12V6C3 5.44772 3.44772 5 4 5Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4 11C4.55228 11 5 11.4477 5 12V18C5 18.0187 5.00689 18.1594 5.28011 18.4067C5.55297 18.6538 6.01363 18.9298 6.69427 19.185C8.05244 19.6943 9.96153 20 12 20C14.0385 20 15.9476 19.6943 17.3057 19.185C17.9864 18.9298 18.447 18.6538 18.7199 18.4067C18.9931 18.1594 19 18.0187 19 18V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V18C21 18.777 20.5855 19.4156 20.0622 19.8894C19.5385 20.3634 18.8276 20.7503 18.008 21.0576C16.3656 21.6736 14.205 22 12 22C9.79501 22 7.63443 21.6736 5.99202 21.0576C5.17237 20.7503 4.46146 20.3634 3.93782 19.8894C3.41454 19.4156 3 18.777 3 18V12C3 11.4477 3.44772 11 4 11Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
