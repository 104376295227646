import { cva } from "class-variance-authority";

export const radioGroupItemCardVariants = cva(
    "flex gap-2 rounded-lg cursor-pointer items-start border border-main-grey-200 hover:border-primary-lighter data-[state=checked]:border-primary-base data-[state=checked]:bg-primary-lightest transition-all bg-main-white group ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-base focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
    {
        variants: {
            size: {
                default: "pl-6 pr-4 py-4",
                sm: "pl-3 py-4 pr-2",
            },
        },
        defaultVariants: {
            size: "default",
        },
    }
);
