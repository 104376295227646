import { cva, VariantProps } from "class-variance-authority";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { forwardRef } from "react";

const linkVariants = cva("inline-flex gap-1 font-semibold", {
    variants: {
        variant: {
            neutral: "text-primary-darkest hover:text-main-grey-500",
            default: "text-primary-base hover:text-primary-darker",
        },
        size: {
            default: "text-body-base",
            sm: "text-body-sm",
            xs: "text-body-xs",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
});

// Types are from the Next.js Link component
export interface LinkProps
    extends Omit<
            React.AnchorHTMLAttributes<HTMLAnchorElement>,
            keyof NextLinkProps
        >,
        NextLinkProps,
        React.RefAttributes<HTMLAnchorElement>,
        VariantProps<typeof linkVariants> {
    children?: React.ReactNode;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ className, variant, size, children, ...props }, ref) => {
        return (
            <NextLink
                {...props}
                className={linkVariants({ variant, size, className })}
                ref={ref}
            >
                {children}
            </NextLink>
        );
    }
);

Link.displayName = "Link";

export { Link };
