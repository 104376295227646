import { isNil } from "lodash";
import { createContext, useEffect, useReducer } from "react";

export type TypeGenericFilteringItemsTabs = "ALL" | string;

export interface StateGenericFilteringItemsWithTabs {
    tab: TypeGenericFilteringItemsTabs;
    query: string;
    data: any[];
}

export enum TypeGenericFilteringItemsWithTabsAction {
    SET_TAB,
    SET_QUERY,
    SET_DATA,
}

export type GenericFilteringItemsWithTabsAction<DataFiltering> =
    | {
          type: TypeGenericFilteringItemsWithTabsAction.SET_TAB;
          payload: TypeGenericFilteringItemsTabs;
      }
    | {
          type: TypeGenericFilteringItemsWithTabsAction.SET_QUERY;
          payload: string;
      }
    | {
          type: TypeGenericFilteringItemsWithTabsAction.SET_DATA;
          payload: DataFiltering[];
      };

const initialState: StateGenericFilteringItemsWithTabs = {
    tab: "ALL",
    query: "",
    data: [],
};

type Reducer<T> = React.Reducer<
    StateGenericFilteringItemsWithTabs,
    GenericFilteringItemsWithTabsAction<T>
>;

const reducer: Reducer<any> = (
    state: StateGenericFilteringItemsWithTabs,
    action: GenericFilteringItemsWithTabsAction<any>
) => {
    switch (action.type) {
        case TypeGenericFilteringItemsWithTabsAction.SET_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case TypeGenericFilteringItemsWithTabsAction.SET_TAB:
            return {
                ...state,
                tab: action.payload,
            };
        case TypeGenericFilteringItemsWithTabsAction.SET_QUERY:
            return {
                ...state,
                query: action.payload,
            };
        default:
            return state;
    }
};

export const GenericFilteringItemsWithTabs = createContext<{
    state: StateGenericFilteringItemsWithTabs;
    dispatch: any;
}>({
    state: initialState,
    dispatch: () => null,
});

interface Props<DataFiltering> {
    children: (props: { data?: DataFiltering[]; query: string }) => JSX.Element;
    data?: DataFiltering[];
    filteringCallback?: (props: {
        data: DataFiltering[];
        filters: { query: string; tab: TypeGenericFilteringItemsTabs };
    }) => DataFiltering[];
}

export const GenericFilteringItemsWithTabsProvider = <DataFiltering,>({
    children,
    data = [],
    filteringCallback,
}: Props<DataFiltering>) => {
    const [state, dispatch] = useReducer<Reducer<DataFiltering>>(reducer, {
        ...initialState,
        data,
    });

    useEffect(() => {
        dispatch({
            type: TypeGenericFilteringItemsWithTabsAction.SET_DATA,
            payload: data,
        });
    }, [data]);

    return (
        <GenericFilteringItemsWithTabs.Provider
            value={{
                state,
                dispatch,
            }}
        >
            <>
                {!isNil(filteringCallback)
                    ? children({
                          data: filteringCallback({
                              data: state.data,
                              filters: {
                                  query: state.query,
                                  tab: state.tab,
                              },
                          }),
                          query: state.query,
                      })
                    : children({
                          query: state.query,
                      })}
            </>
        </GenericFilteringItemsWithTabs.Provider>
    );
};

export default {
    Context: GenericFilteringItemsWithTabs,
    Provider: GenericFilteringItemsWithTabsProvider,
};
