import { SvgProps } from "@design-system/Icon/types";

export const Question = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11.5 6C10.7914 6 10.1371 6.24263 9.67592 6.63794C9.21949 7.02916 9 7.52368 9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8C7 6.88502 7.518 5.85342 8.37434 5.11942C9.2259 4.38951 10.3521 4 11.5 4H12.5C13.6479 4 14.7741 4.38951 15.6257 5.11942C16.4747 5.84714 16.9911 6.86738 16.9999 7.97139C17.0424 8.82755 16.8088 9.67502 16.333 10.3887C15.8631 11.0935 15.182 11.6305 14.3881 11.9231C14.0272 12.104 13.6643 12.4602 13.3935 13.0019C13.1142 13.5604 12.9689 14.2483 12.9991 14.9575C13.0226 15.5093 12.5943 15.9756 12.0425 15.9991C11.4907 16.0226 11.0244 15.5943 11.0009 15.0425C10.9574 14.0203 11.1624 12.9918 11.6046 12.1075C12.0461 11.2246 12.7214 10.495 13.5755 10.0946C13.6058 10.0804 13.6367 10.0677 13.6683 10.0566C14.0773 9.91281 14.4284 9.64 14.6689 9.27928C14.9094 8.91855 15.0262 8.48946 15.0016 8.05663C15.0005 8.03777 15 8.01889 15 8C15 7.52368 14.7805 7.02916 14.3241 6.63794C13.8629 6.24263 13.2086 6 12.5 6H11.5Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 18C12.5523 18 13 18.4477 13 19V19.01C13 19.5623 12.5523 20.01 12 20.01C11.4477 20.01 11 19.5623 11 19.01V19C11 18.4477 11.4477 18 12 18Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
