import { System } from "@design-system/index";
import React, { useState } from "react";
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal";

interface Props {
    children: (props: {
        loading: boolean;
        setLoading: React.Dispatch<React.SetStateAction<boolean>>;
        closeModal: () => void;
    }) => React.ReactNode;
    title?: string;
    description?: string | React.ReactNode;
}

export const ModalDefault = ({ children, title, description }: Props) => {
    const { closeModal } = useGenericModal();

    const [loading, setLoading] = useState(false);

    return (
        <>
            <div>
                {title && <System.Title className="mb-2">{title}</System.Title>}
                {description && <System.Text>{description}</System.Text>}
            </div>

            <div>
                {children({
                    loading,
                    setLoading,
                    closeModal,
                })}
            </div>
        </>
    );
};
