import { SvgProps } from "@design-system/Icon/types";

export const Folder = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    strokeWidth={strokeWidth}
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M5 5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8H12C11.7348 8 11.4804 7.89464 11.2929 7.70711L8.58579 5H5ZM2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3H9C9.26522 3 9.51957 3.10536 9.70711 3.29289L12.4142 6H19C19.7957 6 20.5587 6.31607 21.1213 6.87868C21.6839 7.44129 22 8.20435 22 9V17C22 17.7957 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7957 20 19 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V6C2 5.20435 2.31607 4.44129 2.87868 3.87868Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
};
