import { SvgProps } from "@design-system/Icon/types";

export const Logo = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1133.9 1133.9"
        >
            <path
                strokeWidth={strokeWidth}
                fill="currentColor"
                d="M572.9,508.7C572.9,508.6,572.9,508.6,572.9,508.7c29.7,3.4,56.6,16.4,77.2,36.1c10.3,9.8,26.9,9.7,37.1-0.2
                c24.1-23.4,57-38,93.3-38.1c23.9-0.1,46.4,6.1,65.9,16.9c6,3.3,13.4-0.7,13.4-7.6c0-0.1,0-0.1,0-0.2
                c-0.8-161.6-127.8-293-287.1-301.4v-24.3c0-10.2-8.3-18.5-18.5-18.5c-10.2,0-18.5,8.3-18.5,18.5v24.5
                c-158.6,10.7-283.6,143-282.8,304.2c0,0.1,0,0.1,0,0.2c0,6.9,7.5,10.8,13.5,7.5c19.4-11,41.8-17.4,65.7-17.6
                c36.3-0.2,69.3,14,93.7,37.2c10.3,9.8,26.9,9.7,37.1-0.2c19.7-19.1,45.2-32.1,73.6-36.4C536.8,509.2,554,506.4,572.9,508.7z"
            />
            <path
                strokeWidth={strokeWidth}
                fill="currentColor"
                d="M669.3,670.1c-20.2,0-40.1,4.3-58.4,12.2v77.9c15.5-15.8,36.2-24.5,58.4-24.5c45.3,0,82.1,36.8,82.1,82.1
                s-36.8,82.1-82.1,82.1c-45.3,0-82.1-36.8-82.1-82.1v-46.3c0,0,0,0,0.1,0V695c0,0,0,0-0.1,0V588.8c0-18.1-14.7-32.8-32.8-32.8
                c-18.1,0-32.8,14.7-32.8,32.8v228.9c0,81.4,66.2,147.6,147.6,147.6c81.4,0,147.6-66.2,147.6-147.6
                C816.9,736.3,750.7,670.1,669.3,670.1z"
            />
        </svg>
    );
};
