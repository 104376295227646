import { System } from "@design-system/index";
import { UptimeTimelineContainer } from "../UptimeTimelineContainer";

export interface TimelineItemPaused {
    user: string;
    date: string;
}

interface Props {
    data: TimelineItemPaused;
}

export function UptimeTimelinePaused({ data }: Props) {
    const { user, date } = data;
    return (
        <UptimeTimelineContainer>
            <span className="flex items-center justify-center w-8 h-8 mx-1 rounded-full bg-gray-100 ring-white ring-4">
                <System.Svg.PlayerPause className="h-5 w-5 text-gray-900" />
            </span>
            <System.Text>
                <System.Strong>{user}</System.Strong> paused the monitor
            </System.Text>
            <System.Text className="whitespace-nowrap">{date}</System.Text>
        </UptimeTimelineContainer>
    );
}
