export const SuspenseFallbackFull = () => {
    return (
        <>
            <div className="border border-gray-300 rounded-md p-4  w-full">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-300 h-12 w-12"></div>
                    <div className="flex-1 space-y-2 py-1 border-r border-gray-200 pr-4">
                        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex-1 space-y-2 py-1 border-r border-gray-200 pr-4">
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex-1 space-y-2 py-1 border-r border-gray-200 pr-4">
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex-1 space-y-2 py-1 pr-4">
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
            <div className="border border-gray-300 rounded-md p-4 mt-2 w-full">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-300 h-12 w-12"></div>
                    <div className="flex-1 space-y-2 py-1 border-r border-gray-200 pr-4">
                        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex-1 space-y-2 py-1 border-r border-gray-200 pr-4">
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex-1 space-y-2 py-1 border-r border-gray-200 pr-4">
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex-1 space-y-2 py-1 pr-4">
                        <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
        </>
    );
};
