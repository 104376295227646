import { SvgProps } from "@design-system/Icon/types";

export const Note = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M20.7071 12.2929C21.0976 12.6834 21.0976 13.3166 20.7071 13.7071L13.7071 20.7071C13.3166 21.0976 12.6834 21.0976 12.2929 20.7071C11.9024 20.3166 11.9024 19.6834 12.2929 19.2929L19.2929 12.2929C19.6834 11.9024 20.3166 11.9024 20.7071 12.2929Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V13C21 13.5523 20.5523 14 20 14H14V20C14 20.5523 13.5523 21 13 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6C3 5.20435 3.31607 4.44129 3.87868 3.87868ZM6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H12V14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12H19V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H6Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
