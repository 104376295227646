export const SuspenseFallbackTable = () => {
    return (
        <div className="border rounded-lg p-2">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-gray-400 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-400 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-400 rounded col-span-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
