import { UptimeStatusComponent } from "./UptimeStatus";
import { UptimeTimeline } from "./UptimeTimeline";
import { UptimeRateChart } from "./UptimeRateChart";
import { UptimeMonitorRegion } from "./UptimeMonitorRegion";

export const Uptime = {
    RateChart: UptimeRateChart,
    Status: UptimeStatusComponent,
    Timeline: UptimeTimeline,
    MonitorRegion: UptimeMonitorRegion,
};
