import { SvgProps } from "@design-system/Icon/types";

export const AlertTriangle = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                strokeWidth={strokeWidth}
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
            />
            <path
                strokeWidth={strokeWidth}
                d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z"
            />
            <path strokeWidth={strokeWidth} d="M12 9v4" />
            <path strokeWidth={strokeWidth} d="M12 17h.01" />
        </svg>
    );
};
