import classNames from "classnames";
import { isEmpty } from "lodash";
import { useState } from "react";
import { System } from "@design-system/index";

interface Props {
    imageUrl?: string;
    className?: string;
    defaultIcon?: React.ReactNode;
}

export const FromWordPressFavicon = ({
    imageUrl = "",
    defaultIcon = <System.Svg.Plugin className="w-5 h-5" />,
    className = "",
}: Props) => {
    const [imageInError, setImageInError] = useState(false);

    const onErrorImage = () => {
        setImageInError(true);
    };

    const sizeClasses = "h-10 w-10";
    const classes = classNames(
        "rounded overflow-hidden object-cover",
        {
            "bg-gray-100 flex items-center justify-center":
                isEmpty(imageUrl) || imageInError,
        },
        sizeClasses,
        className
    );

    return (
        <figure className={classes}>
            {!isEmpty(imageUrl) && !imageInError ? (
                <img
                    src={imageUrl}
                    className={sizeClasses}
                    onError={onErrorImage}
                />
            ) : (
                defaultIcon
            )}
        </figure>
    );
};
