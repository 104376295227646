import { System } from "@design-system/index";
import classNames from "classnames";

interface Props {
    className?: string;
    checkedRows: Set<string | number>;
    rowKeys: Set<string | number>;
    clearCheckedRows: () => void;
    checkAllRows: () => void;
}

export const BulkActionsCheckedRows = ({
    className = "",
    checkedRows,
    rowKeys,
    clearCheckedRows,
    checkAllRows,
}: Props) => {
    const classes = classNames("text-sm text-gray-600 flex-1", className);

    return (
        <div className={classes}>
            <System.Button.Default
                className="!font-normal underline text-black -ml-2"
                onClick={() => clearCheckedRows()}
                disabled={checkedRows.size === 0}
            >
                Deselect All
            </System.Button.Default>
            /
            <System.Button.Default
                className="!font-normal underline text-black"
                onClick={() => checkAllRows()}
                disabled={checkedRows.size === rowKeys.size}
            >
                Select All
            </System.Button.Default>
            <System.Text className="ml-2" as="span">
                {checkedRows.size} item
                {checkedRows.size > 1 && "s"} selected
            </System.Text>
        </div>
    );
};
