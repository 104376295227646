import { cn } from "@helpers/utils";
import { isNil } from "lodash";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    ref?: React.Ref<HTMLInputElement>;
    isDisabled?: boolean;
    isChecked?: boolean;
    name?: string;
    register?: any;
    withContext?: boolean;
    registerOptions?: any;
}

const InputToken = forwardRef<HTMLInputElement, Props>(
    ({ type, isDisabled, className, ...rest }, ref) => {
        const classes = cn(
            "block text-gray-900 placeholder:text-gray-400",
            {
                "border border-gray-200 bg-white focus-visible:border-gray-300 focus-visible:ring-offset-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 sm:text-sm sm:leading-6":
                    type !== "file" &&
                    type !== "radio" &&
                    type !== "range" &&
                    type !== "checkbox",
                "py-2 px-4":
                    type !== "color" &&
                    type !== "file" &&
                    type !== "radio" &&
                    type !== "checkbox" &&
                    type !== "range",
                "cursor-pointer text-indigo-600 focus-visible:ring-indigo-600 border-gray-300":
                    !isDisabled && (type === "checkbox" || type === "radio"),
                "cursor-not-allowed bg-gray-200 text-gray-200 border-gray-200":
                    isDisabled,
                "h-4 w-4 rounded": type === "checkbox" || type === "radio",
                "h-8 w-8": type === "color",
                "rounded-full": type !== "checkbox" && type !== "file",
            },
            className
        );

        return (
            <input
                ref={ref}
                className={classes}
                disabled={isDisabled}
                type={type}
                {...rest}
            />
        );
    }
);

const FormInputWithContext = forwardRef<HTMLInputElement, Props>(
    (
        { className, name, type, isDisabled, registerOptions = {}, ...rest },
        ref
    ) => {
        const context = useFormContext();

        return (
            <InputToken
                ref={ref}
                type={type}
                isDisabled={isDisabled}
                className={className}
                {...context.register(name, registerOptions)}
                {...rest}
            />
        );
    }
);

export const FormInput = forwardRef<HTMLInputElement, Props>(
    (
        {
            className = "",
            isChecked,
            isDisabled,
            name = "",
            type = "text",
            withContext = false,
            ...rest
        },
        ref
    ) => {
        if (withContext) {
            return (
                <FormInputWithContext
                    name={name}
                    className={className}
                    isDisabled={isDisabled}
                    type={type}
                    {...rest}
                />
            );
        }

        if (!isNil(rest.register)) {
            return (
                <InputToken
                    type={type}
                    className={className}
                    disabled={isDisabled}
                    name={name}
                    {...rest}
                    {...rest.register}
                />
            );
        }

        return (
            <InputToken
                ref={ref}
                type={type}
                className={className}
                {...rest}
                checked={isChecked}
                isDisabled={isDisabled}
                name={name}
            />
        );
    }
);
