import { SvgProps } from "@design-system/Icon/types";

export const Dot = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            aria-hidden="true"
            className="fill-slate-900"
        >
            <circle cx="1" cy="1" r="1"></circle>
        </svg>
    );
};
