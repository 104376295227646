import classNames from "classnames";
import {
    ButtonDefault,
    ButtonOrLinkProps,
} from "@design-system/Button/Default";

export const ButtonOutlined = ({
    children,
    className = "",
    disabled,
    ...rest
}: ButtonOrLinkProps) => {
    const classes = classNames(
        "border border-gray-200",
        {
            "hover:border-gray-300 bg-white hover:bg-gray-50 text-gray-900":
                !disabled,
            "bg-gray-200": disabled,
        },
        className
    );

    return (
        <ButtonDefault className={classes} disabled={disabled} {...rest}>
            {children}
        </ButtonDefault>
    );
};
