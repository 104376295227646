import classNames from "classnames";
import { ButtonOrLinkProps } from "@design-system/Button/Default";
import { System } from "@design-system/index";

interface Props extends ButtonOrLinkProps {
    isClickable?: boolean;
}

export const StepCurrentButton = ({
    children,
    className = "",
    isClickable = false,
    href,
    ...rest
}: Props) => {
    const classes = classNames(
        "border-2 rounded-full border-indigo-600 relative !bg-indigo-100 text-indigo-600 hover:border-indigo-700 hover:text-indigo-700",
        className
    );
    return (
        <>
            {isClickable ? (
                <System.Button.Outlined
                    iconOnly
                    size="large"
                    href={href}
                    className={classes}
                    {...rest}
                >
                    <System.Svg.Update className="w-6 h-6" />
                    <System.Strong
                        size="small"
                        className="absolute top-full pt-2 left-1/2 -translate-x-1/2 whitespace-nowrap"
                    >
                        {children}
                    </System.Strong>
                </System.Button.Outlined>
            ) : (
                <span className={classes}>
                    <System.IconBullet icon="Update" color="light-indigo" />
                    <System.Strong
                        size="small"
                        className="absolute top-full pt-2 left-1/2 -translate-x-1/2 whitespace-nowrap"
                    >
                        {children}
                    </System.Strong>
                </span>
            )}
        </>
    );
};
