import React from "react";
import classNames from "classnames";

interface Props {
    children: React.ReactNode;
    color?: "blue" | "red" | "green" | "gray";

    className?: string;
}

export const BadgeInformation = ({
    children,
    color = "blue",
    className,
}: Props) => (
    <span
        className={classNames(
            {
                "bg-blue-50 text-blue-600": color === "blue",
                "bg-red-50 text-red-600": color === "red",
                "bg-green-50 text-green-600": color === "green",
                "bg-gray-50 text-gray-600": color === "gray",
            },
            `inline-flex items-center px-2 py-1 rounded-full font-medium text-sm`,
            className
        )}
    >
        {children}
    </span>
);
