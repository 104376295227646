import { cn } from "@helpers/utils";
import classNames from "classnames";
import { ElementType, PropsWithChildren } from "react";

type TextSize = "default" | "small" | "large";
export interface TextProps extends PropsWithChildren {
    size?: TextSize;
    className?: string;
    as?: ElementType;
    onClick?: () => void;
    noColor?: boolean;
}

export const Text = ({
    children,
    className = "",
    as = "p",
    size = "default",
    noColor = false,
    ...rest
}: TextProps) => {
    const Element = as;
    const classes = classNames(
        {
            "text-gray-600": !noColor,
            "text-xs": size === "small",
            "text-sm": size === "default",
            "text-base": size === "large",
        },
        className
    );

    return (
        <Element className={cn(classes)} {...rest}>
            {children}
        </Element>
    );
};
