import classNames from "classnames";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    isStacked?: boolean;
}

export const TableBody = ({ children, isStacked }: Props) => {
    const classes = classNames("flex flex-col w-full", {
        "divide-y divide-gray-200 border border-gray-200 rounded-lg overflow-hidden":
            isStacked,
    });

    return <div className={classes}>{children}</div>;
};
