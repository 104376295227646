import { SvgProps } from "@design-system/Icon/types";

export const CompetitorWpmuDev = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            viewBox="0 0 45 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeWidth={strokeWidth}
                d="M0.666992 22C0.666992 16.3138 0.666992 13.4706 1.38643 11.1619C2.93989 6.17665 6.84364 2.2729 11.8289 0.719434C14.1376 0 16.9807 0 22.667 0C28.3533 0 31.1964 0 33.5052 0.719434C38.4904 2.2729 42.3941 6.17665 43.9476 11.1619C44.667 13.4706 44.667 16.3138 44.667 22C44.667 27.6863 44.667 30.5294 43.9476 32.8382C42.3941 37.8234 38.4904 41.7272 33.5052 43.2806C31.1964 44 28.3533 44 22.667 44C16.9807 44 14.1376 44 11.8289 43.2806C6.84364 41.7272 2.93989 37.8234 1.38643 32.8382C0.666992 30.5294 0.666992 27.6863 0.666992 22Z"
                fill="currentColor"
            />
            <path
                strokeWidth={strokeWidth}
                d="M15.9982 13.0625C14.1757 13.0625 12.6982 14.54 12.6982 16.3625V29.5626C12.6982 30.1701 13.1907 30.6626 13.7982 30.6626C14.4057 30.6626 14.8982 30.1701 14.8982 29.5626V16.3625C14.8982 15.755 15.3907 15.2625 15.9982 15.2625C16.6057 15.2625 17.0982 15.755 17.0982 16.3625L17.0982 27.3624C17.0982 29.185 18.5757 30.6626 20.3982 30.6626C22.2208 30.6626 23.6982 29.185 23.6982 27.3626V24.0625V24.061V16.3625C23.6982 15.755 24.1907 15.2625 24.7982 15.2625C25.4057 15.2625 25.8983 15.755 25.8983 16.3625V25.1626V25.1646V27.3624C25.8983 29.185 27.3757 30.6626 29.1983 30.6626C31.0209 30.6626 32.4983 29.185 32.4983 27.3626V14.1625C32.4983 13.555 32.0057 13.0625 31.3983 13.0625C30.7906 13.0625 30.2983 13.555 30.2983 14.1625V27.3626C30.2983 27.9701 29.8057 28.4626 29.1983 28.4626C28.5906 28.4626 28.0983 27.9701 28.0983 27.3626V24.0625V24.061V16.3625C28.0983 14.54 26.6209 13.0625 24.7982 13.0625C22.9757 13.0625 21.4982 14.54 21.4982 16.3625V25.1626V25.1636L21.4982 27.3626C21.4982 27.9701 21.0057 28.4626 20.3982 28.4626C19.7907 28.4626 19.2982 27.9701 19.2982 27.3626L19.2982 16.3625C19.2982 14.54 17.8208 13.0625 15.9982 13.0625Z"
                fill="currentColor"
            />
        </svg>
    );
};
