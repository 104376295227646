import { SvgProps } from "@design-system/Icon/types";

export const WordPressOutlined = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    strokeWidth={strokeWidth}
                    fill="currentColor"
                    d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12ZM12 3.01C16.97 3.01 20.99 7.03 20.99 12C20.99 16.97 16.97 20.99 12 20.99C7.03 20.99 3.01 16.97 3.01 12C3.01 7.03 7.03 3.01 12 3.01ZM10.01 16.82L6.96 8.61C7.45 8.58 8.01 8.53 8.01 8.53C8.44 8.48 8.39 7.52 7.95 7.54C7.95 7.54 6.66 7.64 5.82 7.64C5.67 7.64 5.49 7.64 5.3 7.63C6.74 5.46 9.2 4.03 12 4.03C14.09 4.03 15.99 4.82 17.41 6.12C16.81 6.04 15.96 6.47 15.96 7.54C15.96 8.2 16.34 8.76 16.75 9.42C17.06 9.96 17.25 10.64 17.25 11.63C17.25 12.97 15.98 16.11 15.98 16.11L13.27 8.61C13.75 8.58 14.02 8.45 14.02 8.45C14.45 8.4 14.4 7.35 13.97 7.37C13.97 7.37 12.67 7.48 11.83 7.48C11.05 7.48 9.72 7.37 9.72 7.37C9.29 7.35 9.24 8.43 9.67 8.45L10.51 8.53L11.63 11.57L10.01 16.82ZM16.03 18.97L18.64 12C18.64 12 19.31 10.31 19.03 8.19C19.66 9.33 19.97 10.61 19.97 12C19.97 14.96 18.41 17.58 16.03 18.97ZM4.68 8.77L8.5 19.25C5.83 17.95 4.03 15.17 4.03 12C4.03 10.84 4.23 9.77 4.68 8.77ZM12.13 13.3L14.42 19.55C13.67 19.82 12.85 19.97 12 19.97C11.28 19.97 10.59 19.86 9.94 19.67L12.13 13.3Z"
                />
            </g>
        </svg>
    );
};
