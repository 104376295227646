import { isNil } from "lodash";

interface Props {
    items: {
        url: string;
    }[];
    total?: number;
    limit?: number;
    labelCounter?: string;
}

export const ListFavicon = ({
    items,
    total,
    limit = 4,
    labelCounter = "other",
}: Props) => {
    return (
        <div className="flex items-center space-x-2">
            <div className="flex flex-shrink-0 -space-x-1">
                <div className="isolate flex -space-x-1 overflow-hidden">
                    {items.map((item, index) => {
                        if (index > 1) {
                            return null;
                        }
                        return (
                            <img
                                key={`icon-${index}`}
                                className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                src={item.url}
                                alt=""
                            />
                        );
                    })}
                </div>
            </div>
            {!isNil(total) && total > limit && (
                <span className="flex-shrink-0 text-xs font-medium leading-5">
                    +{total - limit} {labelCounter}
                </span>
            )}
        </div>
    );
};
