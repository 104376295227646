import { SvgProps } from "@design-system/Icon/types";

export const Mailgun = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <g clipPath="url(#b)">
                    <path
                        fill="#F06B66"
                        d="M12.0013 13.6956C11.0231 13.6956 10.3057 12.913 10.3057 12C10.3057 11.0217 11.0883 10.3043 12.0013 10.3043C12.9144 10.3043 13.697 11.0869 13.697 12C13.697 12.913 12.9144 13.6956 12.0013 13.6956Z"
                    />
                    <path
                        fill="#F06B66"
                        d="M12.001 24C5.41402 24 0.000976562 18.6522 0.000976562 12C0.000976562 5.34783 5.3488 0 12.001 0C18.6532 0 24.001 5.34783 24.001 12C24.001 14.1522 22.2401 15.8478 20.1532 15.8478C19.4358 15.8478 18.7184 15.6522 18.1314 15.2609L18.0662 15.1957V15.2609C16.2401 18.587 12.0662 19.7609 8.74011 17.9348C5.41402 16.1087 4.24011 11.9348 6.06619 8.6087C7.89228 5.28261 12.001 4.17391 15.3271 6C17.4792 7.23913 18.8488 9.52174 18.8488 12C18.8488 12.7174 19.4358 13.2391 20.0879 13.2391C20.7401 13.2391 21.3271 12.6522 21.3271 12C21.3271 6.84783 17.0879 2.6087 11.9358 2.6087C6.78359 2.6087 2.60967 6.84783 2.60967 12C2.60967 17.1522 6.8488 21.3913 12.001 21.3913C14.7401 21.3913 17.3488 20.2174 19.1749 18.0652L21.1966 19.7609C18.8488 22.4348 15.5227 24 12.001 24ZM12.001 7.76087C9.65315 7.76087 7.76185 9.65217 7.76185 12C7.76185 14.3478 9.65315 16.2391 12.001 16.2391C14.3488 16.2391 16.2401 14.3478 16.2401 12C16.2401 9.65217 14.3488 7.76087 12.001 7.76087Z"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
                <clipPath id="b">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
