import { SvgProps } from "@design-system/Icon/types";

export const Outlook = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <g clipPath="url(#b)">
                    <path
                        fill="#0A2767"
                        d="M23.9997 12.558C24.001 12.3703 23.904 12.1957 23.7441 12.0975H23.7413L23.7312 12.092L15.415 7.16917C15.379 7.14491 15.3418 7.12274 15.3033 7.10276C14.9822 6.9371 14.6008 6.9371 14.2797 7.10276C14.2412 7.12275 14.204 7.14491 14.1681 7.16917L5.85181 12.092L5.84177 12.0975C5.5876 12.2556 5.50968 12.5898 5.66774 12.844C5.71431 12.9189 5.77845 12.9812 5.8546 13.0257L14.1709 17.9485C14.2069 17.9726 14.2442 17.9947 14.2825 18.0149C14.6036 18.1806 14.985 18.1806 15.3061 18.0149C15.3445 17.9947 15.3817 17.9726 15.4178 17.9485L23.734 13.0257C23.9002 12.9289 24.0016 12.7503 23.9997 12.558Z"
                    />
                    <path
                        fill="#0364B8"
                        d="M6.82144 9.2712H12.2789V14.2738H6.82144V9.2712ZM22.8836 4.18599V1.89762C22.8967 1.32547 22.4438 0.850868 21.8717 0.837158H7.70945C7.13731 0.850868 6.68444 1.32547 6.69754 1.89762V4.18599L15.0696 6.41854L22.8836 4.18599Z"
                    />
                    <path
                        fill="#0078D4"
                        d="M6.698 4.18591H12.2794V9.20916H6.698V4.18591Z"
                    />
                    <path
                        fill="#28A8EA"
                        d="M17.8607 4.18591H12.2793V9.20916L17.8607 14.2324H22.8839V9.20916L17.8607 4.18591Z"
                    />
                    <path
                        fill="#0078D4"
                        d="M12.2793 9.20923H17.8607V14.2325H12.2793V9.20923Z"
                    />
                    <path
                        fill="#0364B8"
                        d="M12.2793 14.2324H17.8607V19.2557H12.2793V14.2324Z"
                    />
                    <path
                        fill="#14447D"
                        d="M6.82153 14.2738H12.279V18.8215H6.82153V14.2738Z"
                    />
                    <path
                        fill="#0078D4"
                        d="M17.8608 14.2324H22.8841V19.2557H17.8608V14.2324Z"
                    />
                    <path
                        fill="url(#c)"
                        d="M23.7443 12.9951L23.7337 13.0007L15.4174 17.6779C15.3811 17.7002 15.3443 17.7214 15.3057 17.7404C15.1645 17.8077 15.0111 17.8457 14.8548 17.8521L14.4005 17.5864C14.3621 17.5671 14.3248 17.5457 14.2888 17.5222L5.86092 12.7122H5.85702L5.5813 12.5581V22.0264C5.5856 22.6581 6.10103 23.1668 6.73273 23.1628H22.8663C22.8758 23.1628 22.8842 23.1583 22.8942 23.1583C23.0276 23.1498 23.1592 23.1223 23.2849 23.0768C23.3392 23.0538 23.3917 23.0265 23.4417 22.9953C23.4791 22.9741 23.5433 22.9278 23.5433 22.9278C23.8292 22.7162 23.9985 22.3821 23.9999 22.0264V12.5581C23.9997 12.7392 23.902 12.9062 23.7443 12.9951Z"
                    />
                    <path
                        fill="#0A2767"
                        d="M23.5534 12.5206V13.1011L14.8576 19.0882L5.85482 12.7159C5.85482 12.7129 5.85232 12.7104 5.84923 12.7104L5.02319 12.2136V11.795L5.36366 11.7894L6.08365 12.2025L6.10039 12.208L6.16178 12.2471C6.16178 12.2471 14.6232 17.075 14.6455 17.0862L14.9692 17.2759C14.9971 17.2648 15.025 17.2536 15.0585 17.2424C15.0753 17.2313 23.4585 12.515 23.4585 12.515L23.5534 12.5206Z"
                        opacity=".5"
                    />
                    <path
                        fill="#1490DF"
                        d="M23.7442 12.9951L23.7336 13.0013L15.4174 17.6785C15.3811 17.7008 15.3443 17.722 15.3057 17.741C14.9828 17.8988 14.6051 17.8988 14.2821 17.741C14.2439 17.722 14.2066 17.7012 14.1705 17.6785L5.85423 13.0013L5.84419 12.9951C5.68362 12.9081 5.58296 12.7407 5.5813 12.5581V22.0264C5.5853 22.658 6.10055 23.1667 6.73213 23.1628C6.73213 23.1628 6.73216 23.1628 6.73218 23.1628H22.849C23.4806 23.1668 23.9959 22.658 23.9999 22.0264C23.9999 22.0264 23.9999 22.0264 23.9999 22.0264V12.5581C23.9997 12.7392 23.902 12.9062 23.7442 12.9951Z"
                    />
                    <path
                        fill="#0D093F"
                        d="M15.5388 17.6093L15.4143 17.679C15.3783 17.702 15.341 17.723 15.3027 17.7421C15.1656 17.8094 15.0165 17.8489 14.864 17.8582L18.0281 21.5999L23.5475 22.93C23.6988 22.8158 23.8191 22.6655 23.8975 22.493L15.5388 17.6093Z"
                        opacity=".1"
                    />
                    <path
                        fill="#0D093F"
                        d="M16.1025 17.2922L15.4143 17.679C15.3783 17.702 15.341 17.7231 15.3027 17.7421C15.1656 17.8094 15.0165 17.8489 14.864 17.8582L16.3464 21.9454L23.5492 22.9283C23.833 22.7153 24 22.3812 24.0002 22.0264V21.9041L16.1025 17.2922Z"
                        opacity=".05"
                    />
                    <path
                        fill="#28A8EA"
                        d="M6.74781 23.1627H22.8473C23.0951 23.164 23.3367 23.0858 23.5366 22.9395L14.3999 17.5875C14.3615 17.5682 14.3242 17.5468 14.2883 17.5233L5.86036 12.7133H5.85645L5.5813 12.5581V21.994C5.58067 22.6389 6.10293 23.1621 6.74781 23.1627Z"
                    />
                    <path
                        fill="#0D093F"
                        d="M13.3953 6.32531V18.2304C13.3943 18.6478 13.1405 19.023 12.7534 19.1793C12.6335 19.2308 12.5044 19.2574 12.3738 19.2574H5.5813V5.86038H6.69758V5.30225H12.3739C12.9377 5.30438 13.394 5.76145 13.3953 6.32531Z"
                        opacity=".1"
                    />
                    <path
                        fill="#0D093F"
                        d="M12.8371 6.88346V18.7886C12.8385 18.9234 12.8099 19.0568 12.7534 19.1793C12.5984 19.5613 12.228 19.812 11.8157 19.8139H5.5813V5.8604H11.8157C11.9777 5.85877 12.1373 5.89917 12.279 5.97761C12.6211 6.14998 12.837 6.50036 12.8371 6.88346Z"
                        opacity=".2"
                    />
                    <path
                        fill="#0D093F"
                        d="M12.8371 6.88346V17.6723C12.8344 18.2359 12.3793 18.6927 11.8157 18.6976H5.5813V5.8604H11.8157C11.9777 5.85877 12.1373 5.89917 12.279 5.97761C12.6211 6.14998 12.837 6.50036 12.8371 6.88346Z"
                        opacity=".2"
                    />
                    <path
                        fill="#0D093F"
                        d="M12.279 6.88342V17.6722C12.2784 18.2367 11.822 18.6948 11.2576 18.6975H5.5813V5.86035H11.2576C11.822 5.86065 12.2793 6.31845 12.279 6.88287C12.279 6.88305 12.279 6.88323 12.279 6.88342Z"
                        opacity=".2"
                    />
                    <path
                        fill="url(#d)"
                        d="M1.02307 5.86035H11.256C11.821 5.86035 12.2791 6.3184 12.2791 6.88342V17.1163C12.2791 17.6814 11.821 18.1394 11.256 18.1394H1.02307C0.458037 18.1394 0 17.6814 0 17.1163V6.88342C0 6.3184 0.45805 5.86035 1.02307 5.86035Z"
                    />
                    <path
                        fill="#F6F6FE"
                        d="M3.19755 10.1514C3.4497 9.61413 3.8567 9.16455 4.3663 8.86039C4.93065 8.53729 5.5732 8.37621 6.22323 8.39489C6.82569 8.38182 7.42017 8.53455 7.94174 8.83637C8.43212 9.12882 8.82708 9.55731 9.07866 10.0699C9.35265 10.6346 9.4892 11.2562 9.47718 11.8838C9.49046 12.5397 9.34997 13.1896 9.06695 13.7815C8.80937 14.3124 8.40189 14.7561 7.89486 15.0579C7.35318 15.369 6.73663 15.5258 6.11216 15.5112C5.49683 15.526 4.88924 15.3716 4.35569 15.0646C3.86106 14.7718 3.46116 14.3428 3.2037 13.8289C2.92808 13.2723 2.78991 12.6577 2.80072 12.0367C2.78924 11.3864 2.9249 10.7419 3.19755 10.1514ZM4.44331 13.182C4.57775 13.5217 4.80574 13.8162 5.1008 14.0315C5.40134 14.2416 5.76122 14.35 6.12777 14.3407C6.51814 14.3562 6.90292 14.2441 7.22396 14.0215C7.51529 13.8069 7.73728 13.5115 7.86247 13.172C8.00241 12.7928 8.07147 12.3912 8.06619 11.9871C8.07052 11.5791 8.0056 11.1733 7.87419 10.7871C7.75812 10.4383 7.54329 10.1308 7.25577 9.90187C6.94278 9.66869 6.55903 9.55043 6.16907 9.56698C5.79458 9.55728 5.42664 9.6665 5.1181 9.87898C4.81804 10.0952 4.58574 10.3924 4.44833 10.7357C4.14353 11.5228 4.14195 12.395 4.44387 13.1832L4.44331 13.182Z"
                    />
                    <path
                        fill="#50D9FF"
                        d="M17.8608 4.18591H22.8841V9.20916H17.8608V4.18591Z"
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id="c"
                    x1="14.791"
                    x2="14.791"
                    y1="12.558"
                    y2="23.163"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#35B8F1" />
                    <stop offset="1" stopColor="#28A8EA" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1="2.133"
                    x2="10.146"
                    y1="5.061"
                    y2="18.939"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1784D9" />
                    <stop offset=".5" stopColor="#107AD5" />
                    <stop offset="1" stopColor="#0A63C9" />
                </linearGradient>
                <clipPath id="a">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
                <clipPath id="b">
                    <rect
                        width={size}
                        height={size}
                        fill="white"
                        transform="translate(0 .837)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
