import { SvgProps } from "@design-system/Icon/types";

export const Bulb = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 2C12.5523 2 13 2.44772 13 3V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V3C11 2.44772 11.4477 2 12 2ZM4.89289 4.89289C5.28342 4.50237 5.91658 4.50237 6.30711 4.89289L7.00711 5.59289C7.39763 5.98342 7.39763 6.61658 7.00711 7.00711C6.61658 7.39763 5.98342 7.39763 5.59289 7.00711L4.89289 6.30711C4.50237 5.91658 4.50237 5.28342 4.89289 4.89289ZM19.1071 4.89289C19.4976 5.28342 19.4976 5.91658 19.1071 6.30711L18.4071 7.00711C18.0166 7.39763 17.3834 7.39763 16.9929 7.00711C16.6024 6.61658 16.6024 5.98342 16.9929 5.59289L17.6929 4.89289C18.0834 4.50237 18.7166 4.50237 19.1071 4.89289ZM2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12ZM19 12C19 11.4477 19.4477 11 20 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H20C19.4477 13 19 12.5523 19 12Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 8C11.1605 8 10.3422 8.26415 9.66116 8.75503C8.9801 9.24591 8.47075 9.93864 8.20527 10.7351C7.93978 11.5315 7.93163 12.3913 8.18195 13.1927C8.43227 13.994 8.92838 14.6963 9.6 15.2C9.6365 15.2274 9.67108 15.2572 9.7035 15.2893C10.2055 15.7862 10.5835 16.3943 10.8068 17.0644C11.0206 17.7058 11.087 18.3867 11.0016 19.0566C11.0154 19.3012 11.1187 19.5329 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8813 19.5329 12.9846 19.3012 12.9984 19.0566C12.913 18.3867 12.9794 17.7058 13.1932 17.0644C13.4165 16.3943 13.7945 15.7862 14.2965 15.2893C14.3289 15.2572 14.3635 15.2274 14.4 15.2C15.0716 14.6963 15.5677 13.994 15.8181 13.1927C16.0684 12.3913 16.0602 11.5315 15.7947 10.7351C15.5293 9.93864 15.0199 9.24591 14.3388 8.75503C13.6578 8.26415 12.8395 8 12 8ZM8.49174 7.13255C9.51333 6.39622 10.7407 6 12 6C13.2593 6 14.4867 6.39622 15.5083 7.13255C16.5299 7.86887 17.2939 8.90796 17.6921 10.1026C18.0903 11.2973 18.1026 12.587 17.7271 13.789C17.3593 14.9664 16.6378 16.0012 15.6616 16.7532C15.4036 17.0211 15.2084 17.3435 15.0906 17.6969C14.9665 18.0691 14.9317 18.4654 14.9892 18.8535C14.9964 18.902 15 18.951 15 19C15 19.7957 14.6839 20.5587 14.1213 21.1213C13.5587 21.6839 12.7957 22 12 22C11.2043 22 10.4413 21.6839 9.87868 21.1213C9.31607 20.5587 9 19.7957 9 19C9 18.951 9.0036 18.902 9.01078 18.8535C9.06825 18.4654 9.03354 18.0691 8.90945 17.6969C8.79165 17.3435 8.59645 17.0211 8.33839 16.7532C7.36222 16.0012 6.6407 14.9664 6.27292 13.789C5.89744 12.587 5.90968 11.2973 6.3079 10.1026C6.70612 8.90796 7.47014 7.86887 8.49174 7.13255Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8.69995 17C8.69995 16.4477 9.14767 16 9.69995 16H14.3C14.8522 16 15.3 16.4477 15.3 17C15.3 17.5523 14.8522 18 14.3 18H9.69995C9.14767 18 8.69995 17.5523 8.69995 17Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
