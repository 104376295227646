import { StepNavigation } from "./Navigation";
import { StepList } from "./List";
import { StepCurrentButton } from "./CurrentButton";
import { StepPreviousButton } from "./PreviousButton";
import { StepNextButton } from "./NextButton";
export const Step = {
    List: StepList,
    CurrentButton: StepCurrentButton,
    PreviousButton: StepPreviousButton,
    NextButton: StepNextButton,
    Navigation: StepNavigation,
};
