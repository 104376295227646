import classNames from "classnames";
import {
    ButtonDefault,
    ButtonOrLinkProps,
} from "@design-system/Button/Default";

export const ButtonLightDanger = ({
    children,
    className = "",
    disabled = false,
    ...rest
}: ButtonOrLinkProps) => {
    const classes = classNames(
        "transition",
        {
            "hover:text-red-700 text-red-600": !disabled,
            "bg-gray-200 border-gray-200": disabled,
        },
        className
    );

    return (
        <ButtonDefault className={classes} disabled={disabled} {...rest}>
            {children}
        </ButtonDefault>
    );
};
