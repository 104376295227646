import { SvgProps } from "@design-system/Icon/types";

export const Edit = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M13.7929 4.79295C14.5109 4.07498 15.4846 3.67163 16.5 3.67163C17.5154 3.67163 18.4891 4.07498 19.2071 4.79295C19.9251 5.51092 20.3284 6.4847 20.3284 7.50006C20.3284 8.51542 19.9251 9.48919 19.2071 10.2072L8.70711 20.7072C8.51957 20.8947 8.26522 21.0001 8 21.0001H4C3.44772 21.0001 3 20.5523 3 20.0001V16.0001C3 15.7348 3.10536 15.4805 3.29289 15.293L13.7929 4.79295ZM16.5 5.67163C16.0151 5.67163 15.55 5.86427 15.2071 6.20717L5 16.4143V19.0001H7.58579L17.7929 8.79295C18.1358 8.45006 18.3284 7.98499 18.3284 7.50006C18.3284 7.01513 18.1358 6.55006 17.7929 6.20717C17.45 5.86427 16.9849 5.67163 16.5 5.67163Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12.7929 5.79295C13.1834 5.40243 13.8166 5.40243 14.2071 5.79295L18.2071 9.79295C18.5976 10.1835 18.5976 10.8166 18.2071 11.2072C17.8166 11.5977 17.1834 11.5977 16.7929 11.2072L12.7929 7.20717C12.4024 6.81664 12.4024 6.18348 12.7929 5.79295Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
