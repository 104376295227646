import { SvgProps } from "@design-system/Icon/types";

export const Road = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8.27473 4.03849C8.80577 4.19021 9.11326 4.7437 8.96154 5.27473L4.96154 19.2747C4.80981 19.8058 4.25633 20.1133 3.72529 19.9615C3.19426 19.8098 2.88677 19.2563 3.03849 18.7253L7.03849 4.72529C7.19021 4.19426 7.7437 3.88677 8.27473 4.03849Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M15.7253 4.03849C16.2563 3.88677 16.8098 4.19426 16.9615 4.72529L20.9615 18.7253C21.1133 19.2563 20.8058 19.8098 20.2747 19.9615C19.7437 20.1133 19.1902 19.8058 19.0385 19.2747L15.0385 5.27473C14.8868 4.7437 15.1943 4.19021 15.7253 4.03849Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 5.00001C12.5523 5.00001 13 5.44773 13 6.00001V8.00001C13 8.5523 12.5523 9.00001 12 9.00001C11.4477 9.00001 11 8.5523 11 8.00001V6.00001C11 5.44773 11.4477 5.00001 12 5.00001Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 10C12.5523 10 13 10.4477 13 11V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V11C11 10.4477 11.4477 10 12 10Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 15C12.5523 15 13 15.4477 13 16V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V16C11 15.4477 11.4477 15 12 15Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
