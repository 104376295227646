import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { isNil } from "lodash";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    title?: string;
    color?: "red" | "yellow";
}

export function AlertAttention({ title, children, color = "yellow" }: Props) {
    return (
        <div
            className={classNames(
                {
                    "bg-yellow-50": color === "yellow",
                    "bg-red-50": color === "red",
                },
                "rounded-md relative p-4"
            )}
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                        className={classNames(
                            {
                                "text-yellow-400": color === "yellow",
                                "text-red-400": color === "red",
                            },
                            "h-5 w-5"
                        )}
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3 flex-1">
                    {title && (
                        <p
                            className={classNames(
                                {
                                    "text-yellow-800": color === "yellow",
                                    "text-red-800": color === "red",
                                },
                                "text-sm font-medium"
                            )}
                        >
                            {title}
                        </p>
                    )}
                    <div
                        className={classNames(
                            {
                                "text-red-700": color === "red",
                                "text-yellow-700": color === "yellow",
                                "mt-2": !isNil(title),
                            },
                            "text-sm "
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
