import {SiteLabel} from "@design-system/Site/Label";
import {SiteFavicon} from "@design-system/Site/Favicon";
import {SitePreview} from "@design-system/Site/Preview";
import { SiteClient } from "@design-system/Site/Client";

export const Site = {
    Favicon: SiteFavicon,
    Label: SiteLabel,
    Preview: SitePreview,
    Client: SiteClient,
};
