import { PropsWithChildren, createContext, useState } from "react";

export interface PrivateSession {
    user_id: string;
    role: string;
    grants: string[];
    rbac_grants: string[];
    api_key: string;
    isAdmin: boolean | null;
}

interface SessionContextProps {
    session: PrivateSession | null;
    setSession: (session: PrivateSession | null) => void;
}

const PrivateSessionContext = createContext<SessionContextProps>({
    session: null,
    setSession: () => {},
});

interface Props extends PropsWithChildren {
    session?: PrivateSession | null;
}

export const PrivateSession = ({ children, session }: Props) => {
    const [_session, setSession] = useState(session);

    /**
     * Do not delete this code
     * @legacy
     */
    // useEffect(() => {
    //     if (isServer()) {
    //         return;
    //     }

    //     if (!isNil(_session)) {
    //         return;
    //     }

    //     const getPrivateSession = async () => {
    //         const session = (await getSession()) as AuthSession;

    //         if (isNil(session)) {
    //             return;
    //         }

    //         const privateToken = get(session, "user.private_token");
    //         if (!isNil(privateToken)) {
    //             return;
    //         }

    //         const cookie = get(session, "user.privateCookie");
    //         if (isNil(cookie)) {
    //             return;
    //         }

    //         //@ts-ignore
    //         const parsedCookie = parse(cookie);
    //         const [cookieName, cookieValue] = Object.entries(parsedCookie)[0];
    //         const httpOnly = cookie.includes("httponly;");

    //         let options: OptionsType = {
    //             httpOnly: httpOnly,
    //             path: "/",
    //             secure: true,
    //         };

    //         if (Config.production) {
    //             options = {
    //                 ...options,
    //                 domain: ".wp-umbrella.com",
    //             };
    //         }

    //         setCookie(cookieName, cookieValue, options);

    //         const { data } = await OAuthFetcher.getSession();

    //         /**
    //          * If the user is not logged in, we need to delete the cookie from private-api.wp-umbrella.com
    //          */
    //         if (isNil(get(data, "user"))) {
    //             const iframe = document.getElementById("deleteCookieIframe");
    //             const message = JSON.stringify({
    //                 action: "deleteCookie",
    //             });
    //             //@ts-ignore
    //             iframe.contentWindow.postMessage(
    //                 message,
    //                 `${URLCookiePrevent}/cookie-handler`
    //             );

    //             const { data } = await OAuthFetcher.getSession();

    //             if (!isNil(get(data, "user"))) {
    //                 setSession(data);
    //             }
    //             return;
    //         }

    //         setSession(data);
    //     };

    //     getPrivateSession();
    // }, [_session]);

    return (
        <>
            <PrivateSessionContext.Provider
                value={{ session: _session, setSession }}
            >
                {children}
            </PrivateSessionContext.Provider>
        </>
    );
};

export default {
    Context: PrivateSessionContext,
    Provider: PrivateSession,
};
