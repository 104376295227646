import { SvgProps } from "@design-system/Icon/types";

export const Plus = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
