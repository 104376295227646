import { Combobox, Popover, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { System } from "@design-system/index";
import classNames from "classnames";
import { isEmpty, isNil } from "lodash";
import React, { Fragment, useEffect, useState } from "react";

type Item = {
    label: string | React.ReactNode;
    key: string | number | boolean;
    searchValue: string;
    onClick: ({ key }: { key: string | number | boolean }) => void;
    renderBadge?: () => React.ReactNode;
};

interface Props {
    items: Item[];
    label: string;
    onRemove?: ({ key }: { key: string | number | boolean }) => void;
    onCreate?: ({ value }: { value: string | number | boolean }) => void;
    index?: number;
    itemsSelected?: (string | number | boolean)[];
    placeholder?: string;
    position?: "left" | "right";
    createText?: string;
    createOptions?: {
        maxLength?: number;
    };
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    renderNoResults?: ({ query }) => React.ReactNode;
    buttonRef?: React.Ref<HTMLButtonElement>;
}
export const WithSearchCreatable = React.forwardRef(function MenuSelect(
    {
        items,
        label,
        onRemove,
        onCreate,
        index = 1,
        itemsSelected,
        placeholder = "Search...",
        icon: Icon,
        position = "left",
        createText = "",
        createOptions,
        renderNoResults,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
) {
    const [query, setQuery] = useState("");

    useEffect(() => {
        return () => {
            setQuery("");
        };
    }, []);

    const filteredItems =
        isEmpty(query) || isNil(query)
            ? items
            : items.filter((item) => {
                  return item.searchValue
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });

    return (
        <>
            <Popover
                className="relative"
                style={{
                    zIndex: 1400 - index,
                }}
            >
                <div className={classNames("inline-flex items-center gap-2")}>
                    <Popover.Button as={System.Button.Default} ref={ref}>
                        {Icon && <Icon className="w-4 h-4 opacity-50" />}
                        {label}
                    </Popover.Button>
                </div>
                <div className="bg-white">
                    <Transition
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        afterLeave={() => {
                            setQuery("");
                        }}
                    >
                        <Popover.Panel
                            className={classNames(
                                {
                                    "right-0": position === "right",
                                    "left-0": position === "left",
                                },
                                "absolute mt-3 w-screen max-w-sm z-50"
                            )}
                        >
                            {() => {
                                return (
                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="relative bg-white">
                                            <Combobox
                                                onChange={() => {
                                                    setQuery("");
                                                }}
                                            >
                                                <div className="px-3 py-3">
                                                    <System.Form.Input
                                                        type="search"
                                                        className="w-full px-3 py-1.5"
                                                        autoFocus
                                                        placeholder={
                                                            placeholder
                                                        }
                                                        onChange={(event) =>
                                                            setQuery(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {itemsSelected &&
                                                    itemsSelected.map(
                                                        (value) => {
                                                            const item =
                                                                items.find(
                                                                    (item) =>
                                                                        item.key ===
                                                                        value
                                                                );
                                                            if (!item) {
                                                                return null;
                                                            }

                                                            return (
                                                                <React.Fragment
                                                                    key={String(
                                                                        item.key
                                                                    )}
                                                                >
                                                                    <div
                                                                        className="text-sm text-gray-800 relative px-8 hover:bg-indigo-50 border-b py-2 border-gray-100 group/item transition"
                                                                        onClick={() =>
                                                                            onRemove(
                                                                                {
                                                                                    key: item.key,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="flex items-center cursor-pointer">
                                                                            <CheckIcon className="w-4 h-4 stroke-[2px] absolute left-0 ml-2 group-hover/item:text-white" />
                                                                            <System.Text className="group-hover/item:text-white">
                                                                                {
                                                                                    item.label
                                                                                }
                                                                            </System.Text>
                                                                            {item.renderBadge &&
                                                                                item.renderBadge()}
                                                                            <System.Svg.Cross className="w-4 h-4 absolute right-0 mr-2 group-hover/item:text-white" />
                                                                            <XMarkIcon className="w-4 h-4 stroke-[2px] absolute right-0 mr-2 group-hover/item:text-white" />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                {filteredItems.length > 0 && (
                                                    <Combobox.Options
                                                        static
                                                        className="max-h-72 overflow-y-auto text-sm text-gray-800 divide-y pb-2"
                                                    >
                                                        {filteredItems.map(
                                                            (item) => {
                                                                if (
                                                                    itemsSelected &&
                                                                    itemsSelected.includes(
                                                                        item.key
                                                                    )
                                                                ) {
                                                                    return null;
                                                                }

                                                                return (
                                                                    <Combobox.Option
                                                                        key={String(
                                                                            item.key
                                                                        )}
                                                                        value={
                                                                            item.key
                                                                        }
                                                                        onClick={() => {
                                                                            item.onClick(
                                                                                {
                                                                                    key: item.key,
                                                                                }
                                                                            );
                                                                        }}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            classNames(
                                                                                "cursor-pointer select-none py-2 px-8 flex items-center group/item flex-wrap",
                                                                                active &&
                                                                                    "bg-indigo-50 text-white"
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="flex-grow basis-0 shrink-0">
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </span>
                                                                        {item.renderBadge &&
                                                                            item.renderBadge()}
                                                                    </Combobox.Option>
                                                                );
                                                            }
                                                        )}
                                                    </Combobox.Options>
                                                )}

                                                {query !== "" && (
                                                    <>
                                                        <div className="text-center flex items-center px-4 pb-4">
                                                            <System.Button.Default
                                                                className="w-full !font-normal text-gray-600 !gap-0"
                                                                onClick={() => {
                                                                    if (
                                                                        createOptions &&
                                                                        createOptions.maxLength &&
                                                                        query.length >
                                                                            createOptions.maxLength
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    onCreate({
                                                                        value: query,
                                                                    });
                                                                }}
                                                            >
                                                                <System.Svg.Plus className="mr-2 w-4 h-4 opacity-50" />
                                                                Create new{" "}
                                                                {createText} "
                                                                <System.Strong className="flex-none">
                                                                    {query}
                                                                </System.Strong>
                                                                "
                                                            </System.Button.Default>
                                                        </div>
                                                        {createOptions &&
                                                            createOptions.maxLength &&
                                                            query.length >
                                                                createOptions.maxLength && (
                                                                <div className="text-center flex items-center px-8 pt-2 pb-4">
                                                                    <System.Text className="text-red-500 font-bold">
                                                                        Max{" "}
                                                                        {
                                                                            createOptions.maxLength
                                                                        }{" "}
                                                                        characters
                                                                    </System.Text>
                                                                </div>
                                                            )}
                                                        {!isNil(
                                                            renderNoResults
                                                        ) &&
                                                            renderNoResults({
                                                                query,
                                                            })}
                                                    </>
                                                )}
                                            </Combobox>
                                        </div>
                                    </div>
                                );
                            }}
                        </Popover.Panel>
                    </Transition>
                </div>
            </Popover>
        </>
    );
});
