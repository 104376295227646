import classNames from "classnames";
import { System } from "@design-system/index";
import { isEmpty } from "lodash";

interface Props {
    className?: string;
    client: {
        firstname: string;
        lastname: string;
        website_url: string;
        company_name: string;
    };
    onlyAvatar?: boolean;
}

export const SiteClient = ({
    client,
    className = "",
    onlyAvatar = false,
}: Props) => {
    const classes = classNames("flex items-center gap-4", className);

    let initials = "?";

    if (!isEmpty(client.firstname) && !isEmpty(client.lastname)) {
        initials = `${client.firstname.slice(0, 1)}${client.lastname.slice(
            0,
            1
        )}`;
    }

    return (
        <div className={classes}>
            <System.Avatar>{initials}</System.Avatar>
            {!onlyAvatar && (
                <div className="flex flex-col">
                    <System.Text className="font-semibold !text-black">
                        {client.firstname} {client.lastname}
                    </System.Text>
                    {isEmpty(client.website_url) ? (
                        <System.Text>{client.company_name}</System.Text>
                    ) : (
                        <System.ExternalLink
                            className="underline text-gray-600"
                            href={client.website_url}
                        >
                            {client.company_name}
                        </System.ExternalLink>
                    )}
                </div>
            )}
        </div>
    );
};
