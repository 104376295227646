import { System } from "@design-system/index";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { PropsWithChildren, Suspense } from "react";

interface Props extends PropsWithChildren {
    renderSidebar: React.ReactNode;
    sidebarClassName?: string;
}

export const SidebarContainer = ({
    children,
    renderSidebar,
    sidebarClassName = "",
}: Props) => {
    return (
        <div className="flex gap-8">
            <div
                className={classNames({
                    [sidebarClassName]: !isEmpty(sidebarClassName),
                    "min-w-[10%]": isEmpty(sidebarClassName),
                })}
            >
                {renderSidebar}
            </div>
            <div className="flex-1 max-w-[90%]">
                <Suspense fallback={<System.SuspenseFallback.Single />}>
                    {children}
                </Suspense>
            </div>
        </div>
    );
};
