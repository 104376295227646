import { SvgProps } from "@design-system/Icon/types";

export const Gmail = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <g>
                    <path
                        fill="#4285F4"
                        d="M1.63637 20.9719H5.45456V11.728L0 7.64987V19.3406C0 20.2378 0.736365 20.9719 1.63637 20.9719Z"
                    />
                    <path
                        fill="#34A853"
                        d="M18.5469 20.9719H22.365C23.265 20.9719 24.0014 20.2378 24.0014 19.3406V7.64987L18.5469 11.728V20.9719Z"
                    />
                    <path
                        fill="#FBBC04"
                        d="M18.5469 4.6592V11.728L24.0014 7.64987V5.47485C24.0014 3.46294 21.6832 2.29387 20.0741 3.51732L18.5469 4.6592Z"
                    />
                    <path
                        fill="#EA4335"
                        fillRule="evenodd"
                        d="M5.45456 11.728L5.45416 4.6593L11.9996 9.55311L18.545 4.6593V11.7281L11.9996 16.6219L5.45456 11.728Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#C5221F"
                        d="M0 5.47533V7.64987L5.45456 11.728L5.45416 4.6593L3.92728 3.51779C2.31818 2.32153 0 3.46341 0 5.47533Z"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
