import { SvgProps } from "@design-system/Icon/types";

export const Hierarchy = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5C15 5.79565 14.6839 6.55871 14.1213 7.12132C13.5587 7.68393 12.7956 8 12 8C11.2044 8 10.4413 7.68393 9.87868 7.12132C9.31607 6.55871 9 5.79565 9 5C9 4.20435 9.31607 3.44129 9.87868 2.87868ZM12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16C5.79565 16 6.55871 16.3161 7.12132 16.8787C7.68393 17.4413 8 18.2043 8 19C8 19.7957 7.68393 20.5587 7.12132 21.1213C6.55871 21.6839 5.79565 22 5 22C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19C2 18.2043 2.31607 17.4413 2.87868 16.8787ZM5 18C4.73478 18 4.48043 18.1054 4.29289 18.2929C4.10536 18.4804 4 18.7348 4 19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20C5.26522 20 5.51957 19.8946 5.70711 19.7071C5.89464 19.5196 6 19.2652 6 19C6 18.7348 5.89464 18.4804 5.70711 18.2929C5.51957 18.1054 5.26522 18 5 18Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M16.8787 16.8787C17.4413 16.3161 18.2043 16 19 16C19.7957 16 20.5587 16.3161 21.1213 16.8787C21.6839 17.4413 22 18.2043 22 19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22C18.2043 22 17.4413 21.6839 16.8787 21.1213C16.3161 20.5587 16 19.7957 16 19C16 18.2043 16.3161 17.4413 16.8787 16.8787ZM19 18C18.7348 18 18.4804 18.1054 18.2929 18.2929C18.1054 18.4804 18 18.7348 18 19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19C20 18.7348 19.8946 18.4804 19.7071 18.2929C19.5196 18.1054 19.2652 18 19 18Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11.3668 12.226C11.7351 11.9247 12.2649 11.9247 12.6332 12.226L18.1332 16.726C18.5607 17.0758 18.6237 17.7058 18.274 18.1332C17.9242 18.5607 17.2942 18.6237 16.8668 18.274L12 14.2921L7.13324 18.274C6.70579 18.6237 6.07577 18.5607 5.72604 18.1332C5.37632 17.7058 5.43932 17.0758 5.86676 16.726L11.3668 12.226Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
