import dynamic from "next/dynamic";
import { ApexOptions } from "apexcharts";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

type PageLoadTimeInSecond = number;

interface Props {
    options?: ApexOptions;
    value: PageLoadTimeInSecond;
    size?: number;
}

const VALUE_MAX = 20;

export function PageLoadTimeChart({ options, value, size = 210 }: Props) {
    const percentage = (value / VALUE_MAX) * 100;
    const chartSeries = [percentage];

    const chartOptions = {
        plotOptions: {
            radialBar: {
                inverseOrder: false,
                startAngle: -90,
                endAngle: 90,
                dataLabels: {
                    name: {
                        show: false,
                        fontSize: "0px",
                        color: "transparent",
                        offsetY: 0,
                    },
                    value: {
                        offsetY: 0,
                        fontSize: "32px",
                        fontWeight: "500",
                        color: undefined,
                        formatter: function (val) {
                            return `${value}s`;
                        },
                    },
                },
                track: {
                    background: "#E5E7EB",
                    strokeWidth: "64px",
                },
                hollow: {
                    size: "77%",
                },
            },
        },
        fill: {
            colors: [
                function ({ value, seriesIndex, w }) {
                    if (value < (100 * 1) / 3) {
                        return "#00CC66";
                    } else if (
                        value >= (100 * 1) / 3 &&
                        value < (100 * 2) / 3
                    ) {
                        return "#FFAA33";
                    } else {
                        return "#FF3333";
                    }
                },
            ],
        },
        stroke: {
            lineCap: "round",
        },
        ...options,
    };

    return (
        <Chart
            className="h-auto"
            // @ts-ignore
            options={chartOptions}
            series={chartSeries}
            type="radialBar"
            height={size}
            style={{
                marginBottom: (size * -1) / 4,
            }}
        />
    );
}
