"use client";

import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@helpers/utils";

const labelVariants = cva(
    "font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-primary-darkest",
    {
        variants: {
            size: {
                default: "text-body-base",
                sm: "text-body-sm",
            },
        },
        defaultVariants: {
            size: "default",
        },
    }
);

const Label = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
        VariantProps<typeof labelVariants>
>(({ className, size, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants({ size }), className)}
        {...props}
    />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
