import { SvgProps } from "@design-system/Icon/types";

export const DragAndDrop = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6H17C17.7956 6 18.5587 6.31607 19.1213 6.87868C19.6839 7.44129 20 8.20435 20 9V11C20 11.5523 19.5523 12 19 12C18.4477 12 18 11.5523 18 11V9C18 8.73478 17.8946 8.48043 17.7071 8.29289C17.5196 8.10536 17.2652 8 17 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9V17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H11C11.5523 18 12 18.4477 12 19C12 19.5523 11.5523 20 11 20H9C8.20435 20 7.44129 19.6839 6.87868 19.1213C6.31607 18.5587 6 17.7956 6 17V9C6 8.20435 6.31607 7.44129 6.87868 6.87868Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12.2929 12.2929C12.5607 12.0251 12.9569 11.9315 13.3162 12.0513L22.3162 15.0513C22.6999 15.1792 22.9688 15.5257 22.9975 15.9291C23.0262 16.3326 22.809 16.7135 22.4472 16.8944L18.7454 18.7454L16.8944 22.4472C16.7135 22.809 16.3326 23.0262 15.9291 22.9975C15.5257 22.9688 15.1792 22.6999 15.0513 22.3162L12.0513 13.3162C11.9315 12.9569 12.0251 12.5607 12.2929 12.2929ZM14.5811 14.5811L16.1852 19.3934L17.1056 17.5528C17.2023 17.3593 17.3593 17.2023 17.5528 17.1056L19.3934 16.1852L14.5811 14.5811Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 2C3.55228 2 4 2.44772 4 3V3.01C4 3.56228 3.55228 4.01 3 4.01C2.44772 4.01 2 3.56228 2 3.01V3C2 2.44772 2.44772 2 3 2Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7 2C7.55228 2 8 2.44772 8 3V3.01C8 3.56228 7.55228 4.01 7 4.01C6.44772 4.01 6 3.56228 6 3.01V3C6 2.44772 6.44772 2 7 2Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 2C11.5523 2 12 2.44772 12 3V3.01C12 3.56228 11.5523 4.01 11 4.01C10.4477 4.01 10 3.56228 10 3.01V3C10 2.44772 10.4477 2 11 2Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M15 2C15.5523 2 16 2.44772 16 3V3.01C16 3.56228 15.5523 4.01 15 4.01C14.4477 4.01 14 3.56228 14 3.01V3C14 2.44772 14.4477 2 15 2Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 6C3.55228 6 4 6.44772 4 7V7.01C4 7.56228 3.55228 8.01 3 8.01C2.44772 8.01 2 7.56228 2 7.01V7C2 6.44772 2.44772 6 3 6Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 10C3.55228 10 4 10.4477 4 11V11.01C4 11.5623 3.55228 12.01 3 12.01C2.44772 12.01 2 11.5623 2 11.01V11C2 10.4477 2.44772 10 3 10Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 14C3.55228 14 4 14.4477 4 15V15.01C4 15.5623 3.55228 16.01 3 16.01C2.44772 16.01 2 15.5623 2 15.01V15C2 14.4477 2.44772 14 3 14Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
