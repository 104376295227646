import classNames from "classnames";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    className?: string;
    color?: "gray" | "red" | "green";
}

export const Code = ({
    children,
    color = "gray",
    className = "",
    ...rest
}: Props) => {
    const classes = classNames(
        "font-mono rounded-md px-2 py-1",
        {
            "text-red-700 bg-red-50": color === "red",
            "text-green-700 bg-green-50": color === "green",
            "text-gray-900 bg-gray-100": color === "gray",
        },
        className
    );

    return (
        <code className={classes} {...rest}>
            {children}
        </code>
    );
};
