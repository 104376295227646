import classNames from "classnames";
import { forwardRef } from "react";

export interface Props {
    className?: string;
    children: React.ReactNode;
    size: "default" | "large";
}

const BulkActionsContainerElement = (
    { children, size, className = "" }: Props,
    ref
) => {
    return (
        <div
            ref={ref}
            className={classNames(
                {
                    "left-[50%] transform translate-x-[-50%]":
                        size === "default",
                    "left-[35%] transform translate-x-[-15%]": size === "large",
                },
                "border border-gray-300 ring-1 ring-inset ring-white bg-white/50 flex items-center justify-between backdrop-blur-lg rounded-lg fixed z-[4444]  bottom-8 p-4 shadow-lg gap-12",
                className
            )}
        >
            {children}
        </div>
    );
};

export const BulkActionsContainer = forwardRef(BulkActionsContainerElement);
