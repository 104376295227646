import { ContentBody } from "./Body";
import { BulkActions } from "./BulkActions";
import { ContentHeader } from "./Header";
import { Sidebar } from "./Sidebar";

export const Content = {
    Header: ContentHeader,
    Body: ContentBody,
    BulkActions: BulkActions,
    Sidebar: Sidebar,
};
