import { SvgProps } from "@design-system/Icon/types";

export const Sitemap = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M5 16C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H7C7.26522 20 7.51957 19.8946 7.70711 19.7071C7.89464 19.5196 8 19.2652 8 19V17C8 16.7348 7.89464 16.4804 7.70711 16.2929C7.51957 16.1054 7.26522 16 7 16H5ZM2.87868 14.8787C3.44129 14.3161 4.20435 14 5 14H7C7.79565 14 8.55871 14.3161 9.12132 14.8787C9.68393 15.4413 10 16.2043 10 17V19C10 19.7957 9.68393 20.5587 9.12132 21.1213C8.55871 21.6839 7.79565 22 7 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V17C2 16.2044 2.31607 15.4413 2.87868 14.8787Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M17 16C16.7348 16 16.4804 16.1054 16.2929 16.2929C16.1054 16.4804 16 16.7348 16 17V19C16 19.2652 16.1054 19.5196 16.2929 19.7071C16.4804 19.8946 16.7348 20 17 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V17C20 16.7348 19.8946 16.4804 19.7071 16.2929C19.5196 16.1054 19.2652 16 19 16H17ZM14.8787 14.8787C15.4413 14.3161 16.2043 14 17 14H19C19.7957 14 20.5587 14.3161 21.1213 14.8787C21.6839 15.4413 22 16.2043 22 17V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H17C16.2043 22 15.4413 21.6839 14.8787 21.1213C14.3161 20.5587 14 19.7957 14 19V17C14 16.2043 14.3161 15.4413 14.8787 14.8787Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 4C10.7348 4 10.4804 4.10536 10.2929 4.29289C10.1054 4.48043 10 4.73478 10 5V7C10 7.26522 10.1054 7.51957 10.2929 7.70711C10.4804 7.89464 10.7348 8 11 8H13C13.2652 8 13.5196 7.89464 13.7071 7.70711C13.8946 7.51957 14 7.26522 14 7V5C14 4.73478 13.8946 4.48043 13.7071 4.29289C13.5196 4.10536 13.2652 4 13 4H11ZM8.87868 2.87868C9.44129 2.31607 10.2044 2 11 2H13C13.7956 2 14.5587 2.31607 15.1213 2.87868C15.6839 3.44129 16 4.20435 16 5V7C16 7.79565 15.6839 8.55871 15.1213 9.12132C14.5587 9.68393 13.7957 10 13 10H11C10.2043 10 9.44129 9.68393 8.87868 9.12132C8.31607 8.55871 8 7.79565 8 7V5C8 4.20435 8.31607 3.44129 8.87868 2.87868Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8 13C7.73478 13 7.48043 13.1054 7.29289 13.2929C7.10536 13.4804 7 13.7348 7 14V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V14C5 13.2044 5.31607 12.4413 5.87868 11.8787C6.44129 11.3161 7.20435 11 8 11H16C16.7957 11 17.5587 11.3161 18.1213 11.8787C18.6839 12.4413 19 13.2043 19 14V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V14C17 13.7348 16.8946 13.4804 16.7071 13.2929C16.5196 13.1054 16.2652 13 16 13H8Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 8C12.5523 8 13 8.44772 13 9V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V9C11 8.44772 11.4477 8 12 8Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
