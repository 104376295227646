import { SystemSvgTypes } from "@design-system/Icon/types";
import { System } from "..";

interface Props {
    svg: SystemSvgTypes;
    title: string;
}

export function EmptyState({ svg, title }: Props) {
    const Icon = System.Svg[svg];
    return (
        <div className="text-center border-dashed p-8 rounded-lg bg-gray-50/50 border">
            <Icon className="mx-auto h-12 w-12 text-indigo-400" />
            <System.Title className="my-4">{title}</System.Title>
        </div>
    );
}
