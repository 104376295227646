import { SvgProps } from "@design-system/Icon/types";

export const AddWorkspace = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M21.0082 11C21.5604 11.0045 22.0045 11.4558 22 12.0081C21.9975 12.3104 21.9298 12.6087 21.8014 12.8825C21.567 13.3825 20.9715 13.5978 20.4715 13.3634C19.9714 13.1289 19.7561 12.5335 19.9906 12.0334C19.9967 12.0204 19.9999 12.0062 20 11.9918C20.0045 11.4395 20.4559 10.9955 21.0082 11ZM3 10.9999C3.55228 10.9999 4 11.4477 4 11.9999C4 12.0008 3.99998 12.0052 4.00306 12.0152C4.00645 12.0264 4.01483 12.0484 4.03508 12.0814C4.07753 12.1505 4.16411 12.2545 4.32629 12.3823C4.65651 12.6425 5.19631 12.9206 5.95246 13.1726C7.4543 13.6732 9.59257 13.9999 12 13.9999C13.076 13.9999 14.1033 13.9343 15.0473 13.8157C15.5953 13.7469 16.0954 14.1353 16.1642 14.6833C16.233 15.2313 15.8446 15.7313 15.2967 15.8001C14.2667 15.9296 13.156 15.9999 12 15.9999C9.43743 15.9999 7.07571 15.6552 5.32004 15.07C4.44744 14.7792 3.66912 14.4108 3.0884 13.9532C2.51896 13.5044 2 12.852 2 11.9999C2 11.4477 2.44772 10.9999 3 10.9999Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M10.2004 2.16362C12.1965 1.79831 14.2566 2.04895 16.1069 2.88226C17.9573 3.71557 19.5103 5.09211 20.5597 6.82902C21.6091 8.56593 22.1052 10.581 21.9822 12.6066C21.9487 13.1579 21.4746 13.5776 20.9234 13.5441C20.3721 13.5106 19.9524 13.0366 19.9858 12.4853C20.0843 10.8649 19.6874 9.25279 18.8478 7.86326C18.0083 6.47374 16.7659 5.37251 15.2857 4.70586C13.8054 4.03921 12.1573 3.83869 10.5604 4.13094C8.96349 4.42319 7.49327 5.19438 6.34505 6.34206C5.19682 7.48974 4.42494 8.95959 4.13194 10.5564C3.83893 12.1532 4.03867 13.8013 4.70461 15.2819C5.37056 16.7625 6.4712 18.0054 7.86033 18.8456C9.24946 19.6858 10.8613 20.0834 12.4818 19.9858C13.0331 19.9525 13.507 20.3725 13.5402 20.9238C13.5734 21.4751 13.1534 21.9489 12.6022 21.9821C10.5765 22.1042 8.56168 21.6071 6.82527 20.5569C5.08886 19.5067 3.71306 17.953 2.88063 16.1023C2.04819 14.2516 1.79852 12.1914 2.16478 10.1954C2.53104 8.19943 3.49589 6.36211 4.93117 4.92751C6.36645 3.49291 8.20422 2.52893 10.2004 2.16362Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M15 19C15 18.4477 15.4477 17.9999 16 17.9999H22C22.5523 17.9999 23 18.4477 23 19C23 19.5522 22.5523 20 22 20H16C15.4477 20 15 19.5522 15 19Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M19 14.9999C19.5523 14.9999 20 15.4477 20 15.9999V22C20 22.5522 19.5523 23 19 23C18.4477 23 18 22.5522 18 22V15.9999C18 15.4477 18.4477 14.9999 19 14.9999Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
