import { SvgProps } from "@design-system/Icon/types";

export const CompetitorInfiniteWp = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            viewBox="0 0 89 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeWidth={strokeWidth}
                d="M81.7266 7.30372C78.0413 3.43855 72.9177 1.28125 67.3446 1.28125C59.2997 1.28125 52.2884 5.59586 46.221 10.8093C46.6705 10.8543 47.1199 11.079 47.4795 11.3936C47.839 11.7981 48.0637 12.2925 48.0637 12.7869C53.7267 7.88799 60.1986 3.97788 67.3446 3.97788C72.1986 3.97788 76.6031 5.82057 79.7941 9.14642C82.9851 12.4723 84.7379 17.1015 84.7379 22.1801C84.7379 31.9779 76.7379 39.9779 66.9401 39.9779C56.9177 39.9779 49.2772 32.5621 42.4907 26.0453L42.3559 25.9105C41.3222 24.9217 40.3783 24.0228 39.3896 23.079C39.1199 23.9779 38.4458 24.7419 37.5469 25.0565C38.4907 25.9554 39.4345 26.8543 40.4682 27.7981L40.6031 27.9329C47.7491 34.8093 55.839 42.6296 66.8952 42.6296C78.1761 42.6296 87.3896 33.4161 87.3896 22.1352C87.4345 16.4273 85.412 11.1689 81.7266 7.30372Z"
                fill="currentColor"
            />
            <path
                strokeWidth={strokeWidth}
                d="M33.592 23.2577C28.6032 28.3363 22.5358 33.9992 15.5246 33.9992C8.82793 33.9992 3.92905 29.0554 3.92905 22.2689C3.92905 15.6622 9.23242 10.2689 15.7942 10.2689C21.1425 10.2689 25.592 13.6846 29.547 17.4599C29.592 16.9655 29.7717 16.5161 30.1313 16.1565C30.4909 15.797 30.9403 15.5723 31.4347 15.5273C27.1201 11.4374 22.1762 7.57227 15.7942 7.57227C7.74928 7.57227 1.23242 14.179 1.23242 22.2689C1.23242 30.6285 7.2549 36.6959 15.5246 36.6959C23.6144 36.6959 30.1762 30.5835 35.5246 25.1453C34.6257 24.8307 33.9066 24.1565 33.592 23.2577Z"
                fill="currentColor"
            />
            <path
                strokeWidth={strokeWidth}
                d="M46.4906 12.3829C46.3557 12.2481 46.176 12.2031 45.9962 12.2031C45.8164 12.2031 45.6366 12.293 45.5018 12.3829L37.0524 20.6975C36.8726 20.6076 36.6479 20.5627 36.4232 20.5627C36.2883 20.5627 36.1535 20.6076 36.0187 20.6076L32.3333 17.0121C31.9737 16.6526 31.4344 16.6975 31.0748 17.0121C30.8951 17.1919 30.8052 17.4166 30.8052 17.6413C30.8052 17.866 30.8951 18.0908 31.0748 18.2705L34.7602 21.8211C34.7153 21.9559 34.7153 22.0908 34.7153 22.1807C34.7153 23.1245 35.4793 23.8885 36.4232 23.8885C37.367 23.8885 38.131 23.1245 38.131 22.1807C38.131 21.9559 38.0861 21.7762 37.9962 21.5514L46.4456 13.2368C46.7602 13.0571 46.7602 12.6526 46.4906 12.3829Z"
                fill="currentColor"
            />
        </svg>
    );
};
