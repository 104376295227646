import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
    className?: string;
    children: ReactNode;
}

export const GroupStack = ({ children, className = "" }: Props) => {
    const classes = classNames(
        "isolate flex items-center -space-x-2 overflow-hidden",
        className
    );

    return <div className={classes}>{children}</div>;
};
