import { PropsWithChildren, useEffect } from "react";
import { useTable } from "../../hooks/useTable";
import {
    TableChildrenProp,
    TableColumn,
    TableKey,
    TableRow,
    TableRowProcess,
} from "../../types";
import { TableHeaderWithColumns } from "../HeaderWithColumns";
import { TableBodyWithRows } from "../BodyWithRows";
import { get, isEmpty, isNil } from "lodash";
import { useApplication } from "@design-system/Context/GenericApplication/hooks/useApplication";
import { useGenericFilteringItemsWithTabs } from "@design-system/Context/GenericFilteringItemsWithTabs/hooks/useGenericFilteringItemsWithTabs";

interface Props<T, C = {}> {
    children: TableChildrenProp<T>;
    rows: Array<TableRow<T, C>>;
    rowsProcessing?: Set<TableRowProcess>;
    columns: Array<TableColumn>;
    columnsClassname: string;
    withChecked?: boolean;
    withExpanded?: boolean;
}

export const TableKeys = <T, C = {}>({
    children,
    rows,
    rowsProcessing,
    columns,
    columnsClassname = "",
}: Props<T, C>) => {
    const { state, setRowKeys, setRowChildrenKeys, setRowProcessingKeys } =
        useTable();
    const { checkedRows } = state;
    const { setContainerClassName } = useApplication();

    const { state: filterState } = useGenericFilteringItemsWithTabs();

    useEffect(() => {
        if (checkedRows.size > 0) {
            setContainerClassName("pb-24");
        } else {
            setContainerClassName("");
        }
    }, [checkedRows]);
    const { isStacked } = state;

    //  Set the row keys
    useEffect(() => {
        const keys = new Set<TableKey>();
        const subKeys = new Set<TableKey>();

        rows.forEach((row) => {
            // By default, a row is selectable
            if (get(row, "canBeChecked", true)) {
                keys.add(row.id);
            }

            if (!isNil(row.children) && !isEmpty(row.children)) {
                row.children.forEach((child) => {
                    if (get(child, "canBeChecked", true)) {
                        subKeys.add(child.id);
                    }
                });
            }
        });

        setRowKeys(keys);
        setRowChildrenKeys(subKeys);
    }, [rows]);

    // Set the row processing keys
    useEffect(() => {
        if (isNil(rowsProcessing) || isEmpty(rowsProcessing)) {
            setRowProcessingKeys({
                keys: new Set(),
            });
            return;
        }

        setRowProcessingKeys({
            keys: rowsProcessing,
        });
    }, [rowsProcessing]);

    if (rows.length === 0) {
        return (
            <EmptyTableMessage>
                {getEmptyTableMessage(filterState.tab)}
            </EmptyTableMessage>
        );
    }

    return (
        <div className="w-full">
            <TableHeaderWithColumns
                columns={columns}
                columnsClassname={columnsClassname}
            />
            <TableBodyWithRows
                rows={rows}
                columns={columns}
                columnsClassname={columnsClassname}
                isStacked={isStacked}
            >
                {children}
            </TableBodyWithRows>
        </div>
    );
};

const EmptyTableMessage = ({ children }: PropsWithChildren) => {
    return (
        <div className="text-center text-body-md text-main-grey-600">
            {children}
        </div>
    );
};

const getEmptyTableMessage = (tabState: string) => {
    switch (tabState) {
        case "IGNORE":
            return "No excluded updates.";
        case "PROCESS_IN_PROGRESS":
            return "No processes in progress.";
        case "DEACTIVE":
            return "No inactive plugins.";
        case "NEED_UPDATE":
            return "No plugins need updates.";
        case "ACTIVE":
            return "No active plugins.";
        case "ALL":
            return "No plugins.";
    }
};
