import { InformationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { isNil } from "lodash";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    title?: string;
    className?: string;
}

export function AlertInformation({ title, className, children }: Props) {
    return (
        <div
            className={classNames(
                className,
                "rounded-md bg-blue-50 border border-blue-100 p-4"
            )}
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <InformationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3 flex-1">
                    {title && (
                        <p className="text-sm font-medium text-blue-900">
                            {title}
                        </p>
                    )}
                    <div
                        className={classNames(
                            {
                                "mt-2": !isNil(title),
                            },
                            "text-blue-800"
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
