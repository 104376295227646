import { SvgProps } from "@design-system/Icon/types";

export const AddressBook = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8 5C7.73478 5 7.48043 5.10536 7.29289 5.29289C7.10536 5.48043 7 5.73478 7 6V18C7 18.2652 7.10536 18.5196 7.29289 18.7071C7.48043 18.8946 7.73478 19 8 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H8ZM5.87868 3.87868C6.44129 3.31607 7.20435 3 8 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V18C21 18.7957 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7957 21 18 21H8C7.20435 21 6.44129 20.6839 5.87868 20.1213C5.31607 19.5587 5 18.7956 5 18V6C5 5.20435 5.31607 4.44129 5.87868 3.87868Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M9 16C9 15.4477 9.44772 15 10 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H10C9.44772 17 9 16.5523 9 16Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M10.8787 8.87868C11.4413 8.31607 12.2043 8 13 8C13.7957 8 14.5587 8.31607 15.1213 8.87868C15.6839 9.44129 16 10.2043 16 11C16 11.7957 15.6839 12.5587 15.1213 13.1213C14.5587 13.6839 13.7957 14 13 14C12.2043 14 11.4413 13.6839 10.8787 13.1213C10.3161 12.5587 10 11.7957 10 11C10 10.2043 10.3161 9.44129 10.8787 8.87868ZM13 10C12.7348 10 12.4804 10.1054 12.2929 10.2929C12.1054 10.4804 12 10.7348 12 11C12 11.2652 12.1054 11.5196 12.2929 11.7071C12.4804 11.8946 12.7348 12 13 12C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11C14 10.7348 13.8946 10.4804 13.7071 10.2929C13.5196 10.1054 13.2652 10 13 10Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 8C3 7.44772 3.44772 7 4 7H7C7.55228 7 8 7.44772 8 8C8 8.55228 7.55228 9 7 9H4C3.44772 9 3 8.55228 3 8Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 12C3 11.4477 3.44772 11 4 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H4C3.44772 13 3 12.5523 3 12Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3 16C3 15.4477 3.44772 15 4 15H7C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17H4C3.44772 17 3 16.5523 3 16Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
