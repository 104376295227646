import classNames from "classnames";
import { TableKey } from "@design-system/Table/types";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import useLocalStorage from "@hooks/useLocalStorage";
import { SVGWordPress } from "@svg/WordPress";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { System } from "@design-system/index";

interface Props {
    id: TableKey;
    text?: string;
    tooltip?: string;
    projectId?: number;
    withOneClickAdmin?: boolean;
    className?: string;
}

export const CellProcessFailed = ({
    id,
    text = "Process failed!",
    tooltip = null,
    projectId = null,
    withOneClickAdmin = false,
    className = "",
}: Props) => {
    const KEY = `progress_${id}`;
    const [progress, setProgress, removeProgress] = useLocalStorage(KEY, 0);

    useEffect(() => {
        removeProgress();

        return () => {
            removeProgress();
        };
    }, []);

    return (
        <>
            <div
                className={classNames(
                    "w-full flex items-center gap-2",
                    className
                )}
            >
                <ExclamationCircleIcon className="w-4 h-4 text-red-600" />
                <div>
                    <System.Text className="text-red-600">
                        <strong>{text}</strong>
                    </System.Text>
                    {!isEmpty(tooltip) && (
                        <>
                            <p
                                className="text-xs underline cursor-pointer"
                                data-tip
                                data-for={`cell_process_${id}`}
                            >
                                View details
                            </p>
                            <ReactTooltip
                                id={`cell_process_${id}`}
                                effect="solid"
                                className="w-[20%]"
                            >
                                <span>{tooltip}</span>
                            </ReactTooltip>
                        </>
                    )}
                </div>
                {projectId && withOneClickAdmin && (
                    <div className="ml-auto">
                        <System.Button.Outlined
                            href={`/projects/${projectId}/wordpress/login`}
                            target="_blank"
                            className="flex items-center border rounded text-xs p-2 gap-2 cursor-pointer hover:shadow bg-white"
                        >
                            <SVGWordPress className="w-3 h-3" />
                            WP Admin
                        </System.Button.Outlined>
                    </div>
                )}
            </div>
        </>
    );
};
