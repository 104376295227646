import "../styles/index.css";
import React, { ReactElement, ReactNode, useEffect } from "react";
import Config from "@environments/data";
import { get } from "lodash";
import Router from "next/router";
import { SWRConfig } from "swr";
import { SessionProvider } from "next-auth/react";
import type { Session } from "next-auth";

import PrivateSessionContext from "@components/ApplicationSecurity/Session/contexts";

import Head from "../components/Head";
import PopupNotification from "../components/UserNotification/PopupNotification";
import { NotificationContextProvider } from "../contexts/NotificationContext";
import { SlideOverContextProvider } from "../contexts/SlideOverContext";
import { SlideOverUserNotificationsContextProvider } from "../contexts/SlideOverUserNotificationsContext";
import fetcher from "../helpers/fetcher";

import "../styles/nprogress.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Maintenance from "./maintenance";
import { PageDependenciesContextProvider } from "../contexts/PageDependenciesContext";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { Toaster } from "@design-system/Shadcn/ui/sonner";

import { Inter as FontSans } from "next/font/google";
import { cn } from "@helpers/utils";

const fontSans = FontSans({
    subsets: ["latin"],
    variable: "--font-sans",
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps<{
    session: Session;
    data: {
        metas?: {
            title: string;
        };
    };
}> & {
    Component: NextPageWithLayout;
};

Router.events.on("routeChangeComplete", (as, routeProps) => {
    if (!Config.production) {
        return;
    }
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const { data, session } = pageProps;
    const syncLogout = (event: { key: string }) => {
        if (event.key === "logout") {
            Router.push(Config.pages.auth.login);
        }
    };

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }
        window.addEventListener("storage", syncLogout);

        return () => {
            window.removeEventListener("storage", syncLogout);
            window.localStorage.removeItem("logout");
        };
    }, []);

    if (process.env.NEXT_PUBLIC_MAINTENANCE === "true") {
        return <Maintenance />;
    }

    return (
        <SessionProvider session={session}>
            <PrivateSessionContext.Provider>
                <PageDependenciesContextProvider>
                    <NotificationContextProvider>
                        <SlideOverContextProvider>
                            <SlideOverUserNotificationsContextProvider>
                                <Head
                                    title={get(
                                        data,
                                        "metas.title",
                                        Config.app_title
                                    )}
                                />
                                <SWRConfig
                                    value={{
                                        fetcher: fetcher(),
                                    }}
                                >
                                    <main
                                        className={cn(
                                            fontSans.variable,
                                            "h-full" // It's necessary to set the height to 100% for the app "soundless"  (eg. with preview report)
                                        )}
                                    >
                                        <Component
                                            {...data}
                                            //@ts-ignore
                                            pageProps={pageProps}
                                        />
                                    </main>
                                </SWRConfig>
                                <PopupNotification />
                                <Toaster
                                    theme="light"
                                    position="top-right"
                                    richColors
                                    duration={5000}
                                    visibleToasts={2}
                                    closeButton
                                />
                            </SlideOverUserNotificationsContextProvider>
                        </SlideOverContextProvider>
                    </NotificationContextProvider>
                </PageDependenciesContextProvider>
            </PrivateSessionContext.Provider>
        </SessionProvider>
    );
}

export default MyApp;
