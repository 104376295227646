import { SvgProps } from "@design-system/Icon/types";

export const Merge = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L12 4.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 2C12.5523 2 13 2.44772 13 3V8.39393C13 8.39391 13 8.39395 13 8.39393C13.0001 9.66773 12.6857 10.9219 12.0846 12.045C11.4836 13.1681 10.6146 14.1255 9.5547 14.8321C8.76879 15.356 8.1244 16.0659 7.67874 16.8987C7.23308 17.7314 6.99993 18.6614 7 19.6059V21C7 21.5523 6.55228 22 6 22C5.44772 22 5 21.5523 5 21V19.6061C5 19.606 5 19.6061 5 19.6061C4.99992 18.3323 5.31435 17.0781 5.91536 15.955C6.51639 14.8319 7.38542 13.8745 8.4453 13.1679C9.23121 12.644 9.8756 11.9341 10.3213 11.1013C10.7669 10.2686 11.0001 9.33861 11 8.39407V3C11 2.44772 11.4477 2 12 2Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 2C12.5523 2 13 2.44772 13 3V8.39393C12.9999 9.33847 13.2331 10.2686 13.6787 11.1013C14.1244 11.9341 14.7688 12.644 15.5547 13.1679C16.6146 13.8745 17.4836 14.8319 18.0846 15.955C18.6857 17.0781 19.0001 18.3322 19 19.606C19 19.606 19 19.606 19 19.606V21C19 21.5523 18.5523 22 18 22C17.4477 22 17 21.5523 17 21V19.606C17.0001 18.6615 16.7669 17.7314 16.3213 16.8987C15.8756 16.0659 15.2312 15.356 14.4453 14.8321C13.3854 14.1255 12.5164 13.1681 11.9154 12.045C11.3143 10.9219 10.9999 9.66787 11 8.39407C11 8.39405 11 8.39409 11 8.39407V3C11 2.44772 11.4477 2 12 2Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
