import { SvgProps } from "@design-system/Icon/types";

export const Report = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6H6C5.73478 6 5.48043 6.10536 5.29289 6.29289C5.10536 6.48043 5 6.73478 5 7V19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H11.697C12.2493 20 12.697 20.4477 12.697 21C12.697 21.5523 12.2493 22 11.697 22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7957 3 19V7C3 6.20435 3.31607 5.44129 3.87868 4.87868Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M18 13C18.5523 13 19 13.4477 19 14V17H22C22.5523 17 23 17.4477 23 18C23 18.5523 22.5523 19 22 19H18C17.4477 19 17 18.5523 17 18V14C17 13.4477 17.4477 13 18 13Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M13 5C13 4.44772 13.4477 4 14 4H16C16.7956 4 17.5587 4.31607 18.1213 4.87868C18.6839 5.44129 19 6.20435 19 7V11C19 11.5523 18.5523 12 18 12C17.4477 12 17 11.5523 17 11V7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6H14C13.4477 6 13 5.55228 13 5Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M10 4C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5C9 5.26522 9.10536 5.51957 9.29289 5.70711C9.48043 5.89464 9.73478 6 10 6H12C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4H10ZM7.87868 2.87868C8.44129 2.31607 9.20435 2 10 2H12C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5C15 5.79565 14.6839 6.55871 14.1213 7.12132C13.5587 7.68393 12.7956 8 12 8H10C9.20435 8 8.44129 7.68393 7.87868 7.12132C7.31607 6.55871 7 5.79565 7 5C7 4.20435 7.31607 3.44129 7.87868 2.87868Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M14.4645 14.4645C15.4021 13.5268 16.6739 13 18 13C19.3261 13 20.5979 13.5268 21.5355 14.4645C22.4732 15.4021 23 16.6739 23 18C23 19.3261 22.4732 20.5979 21.5355 21.5355C20.5979 22.4732 19.3261 23 18 23C16.6739 23 15.4021 22.4732 14.4645 21.5355C13.5268 20.5979 13 19.3261 13 18C13 16.6739 13.5268 15.4021 14.4645 14.4645ZM18 15C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18C15 18.7957 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7 11C7 10.4477 7.44772 10 8 10H12C12.5523 10 13 10.4477 13 11C13 11.5523 12.5523 12 12 12H8C7.44772 12 7 11.5523 7 11Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M7 15C7 14.4477 7.44772 14 8 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H8C7.44772 16 7 15.5523 7 15Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
