import { PageLoadTimeChart } from "./PageLoadTimeChart"
import { ResponseTimeChart } from "./ResponseTimeChart"
import { PageSpeedChart } from "./PageSpeedChart"
import { Legend } from "./Legend"

export const Performance = {
    PageLoadTimeChart: PageLoadTimeChart,
    PageSpeedChart: PageSpeedChart,
    ResponseTimeChart: ResponseTimeChart,
    Legend: Legend,
}
