import { cn } from "@helpers/utils";
import { VariantProps } from "class-variance-authority";
import { tagVariants } from "./Tag.variants";
import { forwardRef, HTMLAttributes } from "react";

interface TagProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof tagVariants> {}

const Tag = forwardRef<HTMLDivElement, TagProps>(
    ({ variant, size, className, children, ...props }, ref) => {
        return (
            <span
                ref={ref}
                className={cn(tagVariants({ variant, size, className }))}
                {...props}
            >
                {children}
            </span>
        );
    }
);

Tag.displayName = "Tag";

export { Tag };
