import { SvgProps } from "@design-system/Icon/types";

export const Warning = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M10.4744 2.38449C10.9218 2.13274 11.4266 2.00049 11.94 2.00049C12.4534 2.00049 12.9582 2.13274 13.4057 2.38449C13.8531 2.63625 14.2282 2.99902 14.4947 3.43784L14.4979 3.44304L22.9209 17.503L22.9292 17.5171C23.1899 17.969 23.3279 18.4813 23.3293 19.003C23.3307 19.5248 23.1955 20.0378 22.9372 20.491C22.6788 20.9443 22.3064 21.3221 21.8568 21.5868C21.4072 21.8515 20.8961 21.9939 20.3745 21.9999L20.363 22L3.50659 22C2.98489 21.994 2.47385 21.8515 2.02426 21.5868C1.57466 21.3221 1.20219 20.9443 0.943867 20.491C0.685541 20.0378 0.550362 19.5248 0.551769 19.003C0.553176 18.4813 0.69112 17.969 0.951887 17.5171L0.96015 17.5031L9.3853 3.43783C9.65183 2.99901 10.0269 2.63625 10.4744 2.38449ZM11.94 4.00049C11.7701 4.00049 11.6031 4.04425 11.4551 4.12755C11.3077 4.21044 11.1841 4.32971 11.096 4.47396L2.6808 18.5226C2.59669 18.6707 2.55222 18.838 2.55176 19.0084C2.5513 19.181 2.59602 19.3508 2.6815 19.5008C2.76697 19.6508 2.89022 19.7758 3.03898 19.8633C3.18639 19.9501 3.35377 19.9972 3.52475 20H20.3563C20.5273 19.9972 20.6946 19.9501 20.8421 19.8633C20.9908 19.7758 21.1141 19.6508 21.1995 19.5008C21.285 19.3508 21.3297 19.181 21.3293 19.0084C21.3288 18.8381 21.2844 18.6707 21.2003 18.5227L12.7853 4.47608L12.784 4.47397C12.6959 4.32972 12.5723 4.21044 12.425 4.12755C12.2769 4.04425 12.1099 4.00049 11.94 4.00049Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 7.99996C12.5523 7.99996 13 8.44767 13 8.99996V13C13 13.5522 12.5523 14 12 14C11.4477 14 11 13.5522 11 13V8.99996C11 8.44767 11.4477 7.99996 12 7.99996Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5522 12.5623 18 12.01 18H12C11.4477 18 11 17.5522 11 17Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
