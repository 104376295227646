import { FormLabel } from "./Label";
import { FormInput } from "./Input";
import { FormField } from "./Field";
import { FormSubmit } from "./Submit";
import { FormDescription } from "./Description";

export const Form = {
    Label: FormLabel,
    Input: FormInput,
    Field: FormField,
    Description: FormDescription,
    Submit: FormSubmit,
};
