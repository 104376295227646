import { SvgProps } from "@design-system/Icon/types";

export const Link = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M15.707 8.29283C16.0975 8.68336 16.0975 9.31652 15.707 9.70705L9.707 15.707C9.31648 16.0976 8.68332 16.0976 8.29279 15.707C7.90227 15.3165 7.90227 14.6834 8.29279 14.2928L14.2928 8.29283C14.6833 7.90231 15.3165 7.90231 15.707 8.29283Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12.1945 6.14681L11.7567 6.65363C11.3956 7.07158 10.7642 7.11772 10.3462 6.7567C9.92826 6.39567 9.88211 5.76419 10.2431 5.34625L10.7061 4.81025C10.7221 4.79183 10.7386 4.77399 10.7558 4.75678C11.8812 3.63159 13.4074 2.99952 14.9988 2.99963C16.5902 2.99975 18.1164 3.63203 19.2416 4.75738C20.3667 5.88274 20.9988 7.40898 20.9987 9.00036C20.9986 10.5917 20.3663 12.1179 19.241 13.2431C19.2245 13.2596 19.2074 13.2755 19.1898 13.2908L18.6558 13.7548C18.2389 14.117 17.6073 14.0727 17.245 13.6558C16.8828 13.2389 16.9271 12.6073 17.344 12.2451L17.8501 11.8053C18.5858 11.0575 18.9986 10.0501 18.9987 9.00022C18.9988 7.93928 18.5774 6.92175 17.8272 6.1715C17.0771 5.42124 16.0596 4.99971 14.9987 4.99963C13.9492 4.99956 12.9423 5.41186 12.1945 6.14681Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M6.74928 10.3378C7.11497 10.7517 7.07591 11.3836 6.66204 11.7493L6.16024 12.1927C5.79548 12.5582 5.50543 12.9914 5.30641 13.4681C5.10398 13.9529 4.99976 14.4731 4.99976 14.9984C4.99976 15.5238 5.10398 16.044 5.30641 16.5288C5.50883 17.0136 5.80542 17.4535 6.17901 17.8229C6.94055 18.5759 7.96839 18.9983 9.0394 18.9983C10.0864 18.9983 11.0922 18.5947 11.8482 17.873L12.1974 17.4033C12.5269 16.9601 13.1533 16.8679 13.5965 17.1974C14.0397 17.5269 14.1319 18.1533 13.8024 18.5966L13.4054 19.1306C13.3752 19.1712 13.342 19.2094 13.306 19.245C12.1701 20.3683 10.637 20.9983 9.0394 20.9983C7.44185 20.9983 5.90874 20.3683 4.77279 19.245C4.21109 18.6896 3.76516 18.0283 3.46081 17.2994C3.15647 16.5704 2.99976 15.7884 2.99976 14.9984C2.99976 14.2085 3.15647 13.4264 3.46081 12.6975C3.76516 11.9686 4.21109 11.3073 4.77279 10.7519C4.78608 10.7387 4.79974 10.7259 4.81376 10.7136L5.33776 10.2506C5.75163 9.88487 6.38359 9.92393 6.74928 10.3378Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
