import { SvgProps } from "@design-system/Icon/types";

export const StarOutline = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    strokeWidth={strokeWidth}
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M11.9931 1.00195C12.3738 1.00195 12.7214 1.21805 12.8898 1.55939L15.7431 7.34073L22.1225 8.26529C22.4994 8.31992 22.8126 8.58388 22.9302 8.94609C23.0478 9.30831 22.9495 9.70589 22.6766 9.97153L18.0537 14.4715L19.1437 20.8259C19.2081 21.2012 19.0537 21.5805 18.7455 21.8042C18.4373 22.0279 18.0288 22.0572 17.6919 21.8796L11.9996 18.88L6.29347 21.8801C5.95655 22.0572 5.54828 22.0277 5.24032 21.804C4.93237 21.5802 4.77815 21.2011 4.84251 20.8259L5.93254 14.4715L1.3096 9.97153C1.0367 9.70589 0.938377 9.30831 1.056 8.94609C1.17363 8.58388 1.48678 8.31992 1.86368 8.26529L8.24315 7.34073L11.0964 1.55939C11.2648 1.21805 11.6125 1.00195 11.9931 1.00195ZM11.9931 4.26153L9.80385 8.69751C9.65813 8.99278 9.3764 9.19739 9.05054 9.24461L4.15852 9.9536L7.70462 13.4054C7.94065 13.6351 8.0484 13.9664 7.99271 14.291L7.15631 19.1668L11.5347 16.8648C11.8264 16.7115 12.1748 16.7117 12.4663 16.8653L16.8295 19.1645L15.9935 14.291C15.9378 13.9664 16.0456 13.6351 16.2816 13.4054L19.8277 9.9536L14.9357 9.24461C14.6098 9.19739 14.3281 8.99278 14.1824 8.69751L11.9931 4.26153Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
};
