import { ApexOptions } from "apexcharts";
import dynamic from "next/dynamic";
import {
    UPTIME_STATUS_LABELS,
    UptimeStatus,
} from "@design-system/Uptime/UptimeStatus";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const MULTIPLIER = {
    [UptimeStatus.UP]: 11,
    [UptimeStatus.DOWN]: 2,
    [UptimeStatus.PAUSED]: 1,
};

const BAR_WIDTH = 4;

interface Props {
    options?: ApexOptions;
    data: Array<{ date: Date; status: UptimeStatus }>;
}

export function UptimeRateChart({ options, data }: Props) {
    const chartSeries = [
        {
            name: "Uptime Rate",
            data: data.map((item) => {
                let multiplier =
                    MULTIPLIER[item.status] || MULTIPLIER[UptimeStatus.PAUSED];

                return {
                    x: item.date.toString(),
                    y: multiplier * BAR_WIDTH, // To respect the ratio between each label
                };
            }),
        },
    ];

    const chartOptions = {
        chart: {
            zoom: {
                enabled: false,
            },
            offsetX: 0,
            offsetY: 0,
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            row: {
                opacity: 0,
            },
            column: {
                opacity: 0,
            },
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        legend: {
            show: true,
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                columnWidth: BAR_WIDTH,
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: MULTIPLIER[UptimeStatus.PAUSED] * BAR_WIDTH,
                            color: "#E5E7EB", // Paused
                        },
                        {
                            from:
                                MULTIPLIER[UptimeStatus.PAUSED] * BAR_WIDTH + 1,
                            to: MULTIPLIER[UptimeStatus.DOWN] * BAR_WIDTH + 1,
                            color: "#FF3333", // Down
                        },
                        {
                            from: MULTIPLIER[UptimeStatus.DOWN] * BAR_WIDTH + 1,
                            to: MULTIPLIER[UptimeStatus.UP] * BAR_WIDTH,
                            color: "#00CC66", // Up
                        },
                    ],
                },
            },
        },
        // Disabled tooltip inside the chart.
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                const data = series[seriesIndex][dataPointIndex];
                const value = data / BAR_WIDTH;
                const key = Object.keys(MULTIPLIER).find(
                    (key) => MULTIPLIER[key] === value
                );
                const status = UPTIME_STATUS_LABELS[key];

                return `
                    <div class="px-2 py-2 text-xs">
                        ${status}
                    </div>
                `;
            },
        },
        xaxis: {
            type: "datetime",
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
        },
        legends: {
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        stroke: {
            show: false,
        },
        ...options,
    };

    return (
        <Chart
            // @ts-ignore
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={107} // to display the bar chart on Status.Up above 44px.
            width="100%"
        />
    );
}
