import { SvgProps } from "@design-system/Icon/types";

export const TrendDown = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                strokeWidth={strokeWidth}
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
            />
            <path strokeWidth={strokeWidth} d="M3 7l6 6l4 -4l8 8" />
            <path strokeWidth={strokeWidth} d="M21 10l0 7l-7 0" />
        </svg>
    );
};
