import { PropsWithChildren, createContext, useState } from "react";
import { NextRouter } from "next/router";
import { get } from "lodash";

interface PropsCanViewMenu {
    query?: {
        userId?: number;
    };
    router?: NextRouter;
}

export type GenericSidebarMenu<T> = {
    label: string | React.ReactNode;
    value: string | T;
    link?: string | Function;
    canViewMenu?: (props: PropsCanViewMenu) => boolean;
    container?: any;
    containerSubMenu?: any;
    items?: Array<{
        canViewMenu?: (props: PropsCanViewMenu) => boolean;
        label: string;
        value: string | T;
        link?: string | Function;
        container?: any;
    }>;
};

type Sidebar = {
    values: {
        active: any;
        menu: Array<GenericSidebarMenu<any>>;
        byLink?: boolean;
    };
    actions: {
        setMenu: Function;
        setActive: Function;
    };
};

export const GenericSidebarContext = createContext<Sidebar>({
    values: {
        active: null,
        menu: [],
    },
    actions: {
        setMenu: () => {},
        setActive: () => {},
    },
});

interface Props<TMenu = any> extends PropsWithChildren {
    values: {
        menu: Array<GenericSidebarMenu<TMenu>>;
        defaultActive?: any;
        byLink?: boolean;
    };
}

export const GenericSidebarProvider = ({ children, values }: Props) => {
    const [menu, setMenu] = useState(values.menu);
    const [active, setActive] = useState(get(values, "defaultActive", null));

    return (
        <GenericSidebarContext.Provider
            value={{
                values: {
                    menu,
                    active,
                    byLink: get(values, "byLink", false),
                },
                actions: {
                    setMenu,
                    setActive,
                },
            }}
        >
            {children}
        </GenericSidebarContext.Provider>
    );
};

export default {
    Context: GenericSidebarContext,
    Provider: GenericSidebarProvider,
};
