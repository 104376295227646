export const SVGToggleValid = ({className = "bg-white h-3 w-3 text-indigo-600"}) => (
    <svg
        fill="currentColor"
        viewBox="0 0 12 12"
        className={className}
    >
        <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
    </svg>
);
