import { SvgProps } from "@design-system/Icon/types";

export const StarFilled = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        d="M11.9999 17.75L5.82787 20.995L7.00687 14.122L2.00687 9.25495L8.90686 8.25495L11.9929 2.00195L15.0789 8.25495L21.9789 9.25495L16.9789 14.122L18.1579 20.995L11.9999 17.75Z"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11.9929 1.00195C12.3735 1.00195 12.7211 1.21805 12.8896 1.55939L15.7428 7.34073L22.1223 8.26529C22.4992 8.31992 22.8123 8.58388 22.93 8.94609C23.0476 9.30831 22.9493 9.70589 22.6764 9.97153L18.0534 14.4715L19.1435 20.8259C19.2079 21.2012 19.0535 21.5805 18.7453 21.8042C18.4371 22.0279 18.0286 22.0572 17.6917 21.8796L11.9993 18.88L6.29323 21.8801C5.95631 22.0572 5.54803 22.0277 5.24008 21.804C4.93213 21.5802 4.7779 21.2011 4.84226 20.8259L5.9323 14.4715L1.30935 9.97153C1.03646 9.70589 0.938133 9.30831 1.05576 8.94609C1.17338 8.58388 1.48654 8.31992 1.86344 8.26529L8.2429 7.34073L11.0961 1.55939C11.2646 1.21805 11.6122 1.00195 11.9929 1.00195ZM11.9929 4.26153L9.8036 8.69751C9.65788 8.99278 9.37615 9.19739 9.05029 9.24461L4.15827 9.9536L7.70438 13.4054C7.94041 13.6351 8.04816 13.9664 7.99247 14.291L7.15607 19.1668L11.5345 16.8648C11.8261 16.7115 12.1746 16.7117 12.4661 16.8653L16.8293 19.1645L15.9933 14.291C15.9376 13.9664 16.0453 13.6351 16.2814 13.4054L19.8275 9.9536L14.9354 9.24461C14.6096 9.19739 14.3278 8.99278 14.1821 8.69751L11.9929 4.26153Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
