import classNames from "classnames";
import { ButtonOrLinkProps } from "@design-system/Button/Default";
import { System } from "@design-system/index";
import { isNil } from "lodash";

export const StepNextButton = ({
    children,
    className = "",
    href,
    ...rest
}: ButtonOrLinkProps) => {
    const classes = classNames(
        "border-2 border-gray-200 bg-white rounded-full relative",
        className
    );
    return (
        <>
            {!isNil(href) ? (
                <System.Button.Outlined
                    iconOnly
                    size="large"
                    href={href}
                    className={classes}
                    {...rest}
                >
                    <System.Svg.Info className="w-6 h-6" />
                    <System.Strong
                        size="small"
                        className="sr-only absolute top-full pt-2 left-1/2 -translate-x-1/2 whitespace-nowrap"
                    >
                        {children}
                    </System.Strong>
                </System.Button.Outlined>
            ) : (
                <span className={classes} onClick={rest.onClick}>
                    <System.IconBullet icon="Info" color="white" />
                    <System.Strong
                        size="small"
                        className="sr-only absolute top-full pt-2 left-1/2 -translate-x-1/2 whitespace-nowrap"
                    >
                        {children}
                    </System.Strong>
                </span>
            )}
        </>
    );
};
