import { SvgProps } from "@design-system/Icon/types";

export const Mailjet = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <path
                    fill="#9585F4"
                    fillRule="evenodd"
                    d="M-0.000488281 9.27069L8.75655 13.24L10.5161 11.4906L6.03532 9.45483L19.9279 4.13514L14.5877 17.9663L12.5622 13.5264L10.7924 15.286L10.8844 15.4906L14.7617 23.9919L23.9995 0.0839844L-0.000488281 9.27069Z"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
