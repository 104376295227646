import { SvgProps } from "@design-system/Icon/types";

export const Google = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <g>
                    <path
                        fill="#4285F4"
                        d="M23.52 12.2727C23.52 11.4218 23.4436 10.6036 23.3018 9.81812H12V14.4654H18.4582C18.1745 15.9599 17.3236 17.2254 16.0473 18.0763V21.0981H19.9418C22.2109 19.0036 23.52 15.9272 23.52 12.2727Z"
                    />
                    <path
                        fill="#34A853"
                        d="M12.0001 24C15.2401 24 17.9564 22.9309 19.9419 21.0982L16.0474 18.0763C14.9783 18.7963 13.6146 19.2327 12.0001 19.2327C8.88008 19.2327 6.22917 17.1273 5.28008 14.2909H1.28735V17.3891C3.2619 21.3054 7.30917 24 12.0001 24Z"
                    />
                    <path
                        fill="#FBBC05"
                        d="M5.28 14.2799C5.04 13.5599 4.89818 12.7962 4.89818 11.9999C4.89818 11.2035 5.04 10.4399 5.28 9.71989V6.6217H1.28727C0.469091 8.23625 0 10.0581 0 11.9999C0 13.9417 0.469091 15.7635 1.28727 17.3781L4.39636 14.9562L5.28 14.2799Z"
                    />
                    <path
                        fill="#EA4335"
                        d="M12.0001 4.77818C13.7674 4.77818 15.3383 5.38909 16.5928 6.56727L20.0292 3.13091C17.9455 1.18909 15.2401 0 12.0001 0C7.30917 0 3.2619 2.69455 1.28735 6.62182L5.28008 9.72C6.22917 6.88364 8.88008 4.77818 12.0001 4.77818Z"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
