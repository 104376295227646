import { System } from "@design-system/index";

export enum UptimeStatus {
    PAUSED = "paused",
    UP = "up",
    DOWN = "down",
}

const SVG_COLORS = {
    [UptimeStatus.PAUSED]: "text-gray-500",
    [UptimeStatus.UP]: "text-green-500",
    [UptimeStatus.DOWN]: "text-red-500",
};

const LABEL_COLORS = {
    [UptimeStatus.PAUSED]: "text-gray-900",
    [UptimeStatus.UP]: "text-green-700",
    [UptimeStatus.DOWN]: "text-red-700",
};

export const UPTIME_STATUS_LABELS = {
    [UptimeStatus.PAUSED]: "Paused",
    [UptimeStatus.UP]: "Up",
    [UptimeStatus.DOWN]: "Down",
};

interface Props {
    status: UptimeStatus;
    withLabel?: boolean;
    className?: string;
}

export const UptimeStatusComponent = ({
                                          status,
                                          withLabel = false,
                                          className = "",
                                      }: Props) => {
    const svgClassname = SVG_COLORS[status];
    const labelClassname = LABEL_COLORS[status];
    const classnames = `inline-flex items-center gap-2 ${className}`;

    return (
        <span className={classnames}>
            <svg className={svgClassname} width="28" height="28" viewBox="0 0 28 28" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <rect x="10" y="10" width="8" height="8" rx="4" fill="currentColor" />
                <rect width="28" height="28" rx="14" fill="currentColor" opacity={.1} />
            </svg>
            { withLabel && (
                <System.Title className={labelClassname}>{UPTIME_STATUS_LABELS[status]}</System.Title>
            ) }
        </span>
    );
};
