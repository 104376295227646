import { SvgProps } from "@design-system/Icon/types";

export const Monitoring = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeWidth={strokeWidth}
                d="M5.636 19.3639C4.37734 18.1052 3.52019 16.5016 3.17293 14.7558C2.82567 13.0099 3.00391 11.2004 3.6851 9.55582C4.36629 7.9113 5.51984 6.50569 6.99988 5.51677C8.47992 4.52784 10.22 4 12 4C13.78 4 15.5201 4.52784 17.0001 5.51677C18.4802 6.50569 19.6337 7.9113 20.3149 9.55582C20.9961 11.2004 21.1743 13.0099 20.8271 14.7558C20.4798 16.5016 19.6227 18.1052 18.364 19.3639"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                strokeWidth={strokeWidth}
                d="M16 9L12 13"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
