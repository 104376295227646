import { SvgProps } from "@design-system/Icon/types";

export const Send = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M21.7072 2.29292C22.0977 2.68345 22.0977 3.31661 21.7072 3.70714L10.7072 14.7071C10.3167 15.0977 9.68349 15.0977 9.29296 14.7071C8.90244 14.3166 8.90244 13.6834 9.29296 13.2929L20.293 2.29292C20.6835 1.9024 21.3167 1.9024 21.7072 2.29292Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M21.7072 2.29292C21.9813 2.56708 22.0723 2.975 21.9406 3.33967L15.4406 21.3397C15.4312 21.3657 15.4207 21.3914 15.4092 21.4166C15.2855 21.6864 15.087 21.9151 14.8372 22.0754C14.5874 22.2357 14.2969 22.3209 14.0001 22.3209C13.7033 22.3209 13.4127 22.2357 13.1629 22.0754C12.9191 21.9189 12.7242 21.6974 12.6 21.4359L9.25471 14.7454L2.56419 11.4001C2.30276 11.276 2.08118 11.081 1.92471 10.8372C1.76441 10.5874 1.6792 10.2968 1.6792 10C1.6792 9.70323 1.76441 9.41267 1.92471 9.16288C2.08501 8.9131 2.31366 8.71458 2.58347 8.59094C2.60868 8.57939 2.63435 8.56889 2.66043 8.55947L20.6604 2.05947C21.0251 1.92779 21.433 2.01876 21.7072 2.29292ZM4.37183 10.0679L10.4473 13.1056C10.6408 13.2024 10.7977 13.3593 10.8945 13.5528L13.9322 19.6283L19.3359 4.66417L4.37183 10.0679Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
