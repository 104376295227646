import { useContext } from "react";
import { System } from "@design-system/index";

export const useApplication = () => {
    const context = useContext(System.Context.GenericApplication.Context);

    if (!context) {
        throw new Error(
            "useApplication must be used within a System.Context.GenericApplication.Context"
        );
    }

    function setContainerClassname(className: string) {
        context.dispatch({
            type: "SET_CONTAINER_CLASSNAME",
            payload: { className: className },
        });
    }

    return {
        state: context.state,
        setContainerClassName: setContainerClassname,
    };
};
