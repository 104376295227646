import { clone, get, isEmpty, isNil } from "lodash";
import { createContext, useEffect, useState } from "react";

type SlideOverContextType = {
    values: {
        open: boolean;
        data: {
            title: string;
            description: string;
            confirmButton: string;
            component: React.ReactNode;
            canCloseByClickOutside: boolean;
        };
    };
    actions: {
        setOpen: Function;
        setData: Function;
        setCanCloseByClickOutside: Function;
    };
};

export const SlideOverContext = createContext<SlideOverContextType>({
    values: {
        open: false,
        data: {
            title: "",
            description: "",
            confirmButton: "Save",
            component: null,
            canCloseByClickOutside: true,
        },
    },
    actions: {
        setOpen: () => {},
        setData: () => {},
        setCanCloseByClickOutside: () => {},
    },
});

const DEFAULT_DATA = {
    title: "",
    description: "",
    confirmButton: "Save",
    component: null,
};

export const SlideOverContextProvider = ({ values, children }: any) => {
    const [open, setOpen] = useState(get(values, "open", false));
    const [data, setData] = useState(get(values, "data", DEFAULT_DATA));

    useEffect(() => {
        if (
            isEmpty(data.title) &&
            isEmpty(data.description) &&
            isNil(data.component)
        ) {
            return;
        }

        setOpen(true);
    }, [data]);

    useEffect(() => {
        if (open) {
            return;
        }

        setData(clone(DEFAULT_DATA));
    }, [open]);

    const setCanCloseByClickOutside = (canCloseByClickOutside: boolean) => {
        setData((prevData: any) => ({
            ...prevData,
            canCloseByClickOutside,
        }));
    };

    return (
        <SlideOverContext.Provider
            value={{
                values: {
                    open,
                    data,
                },
                actions: {
                    setOpen,
                    setData,
                    setCanCloseByClickOutside,
                },
            }}
        >
            {children}
        </SlideOverContext.Provider>
    );
};

export default { SlideOverContext, SlideOverContextProvider };
