import { TableBody } from "./Body";
import { BulkActions } from "./BulkActions";
import { CellInProgress } from "./CellInProgress";
import { CellProcessFailed } from "./CellProcessFailed";
import { CellProcessFinished } from "./CellProcessFinished";
import { TableHeader } from "./Header";
import { TableInnerRow } from "./InnerRow";
import { TableInnerRowChildren } from "./InnerRowChildren";
import { TableInnerRowWithActions } from "./InnerRowWithActions";
import { TableList } from "./List";
import { TableRow } from "./Row";
import { RowProcessing } from "./RowProcessing";
import { TableRowWithActions } from "./RowWithActions";
import { TableCheckbox } from "./TableCheckbox";

export const Table = {
    Body: TableBody,
    BulkActions: BulkActions,
    Header: TableHeader,
    InnerRow: TableInnerRow,
    InnerRowWithActions: TableInnerRowWithActions,
    InnerRowChildren: TableInnerRowChildren,
    List: TableList,
    Row: TableRow,
    RowWithActions: TableRowWithActions,
    RowProcessing: RowProcessing,
    CellInProgress: CellInProgress,
    CellProcessFailed: CellProcessFailed,
    CellProcessFinished: CellProcessFinished,
    Checkbox: TableCheckbox,
};
