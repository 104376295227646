import { SvgProps } from "@design-system/Icon/types";

export const WPUmbrella = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        d="M12.4205 10.238C12.4205 10.2356 12.4205 10.2356 12.4205 10.238C13.1431 10.3207 13.7975 10.637 14.2987 11.1163C14.5493 11.3547 14.9531 11.3523 15.2013 11.1114C15.7876 10.5421 16.588 10.1869 17.4711 10.1845C18.0526 10.1821 18.6 10.3329 19.0744 10.5957C19.2203 10.6759 19.4004 10.5786 19.4004 10.4108C19.4004 10.4083 19.4004 10.4083 19.4004 10.4059C19.3809 6.47439 16.2912 3.27761 12.4156 3.07325V2.48206C12.4156 2.23391 12.2137 2.03198 11.9656 2.03198C11.7174 2.03198 11.5155 2.23391 11.5155 2.48206V3.07811C7.65696 3.33843 4.61588 6.5571 4.63535 10.4789C4.63535 10.4813 4.63535 10.4813 4.63535 10.4837C4.63535 10.6516 4.81781 10.7465 4.96378 10.6662C5.43576 10.3986 5.98072 10.2429 6.56217 10.238C7.4453 10.2332 8.24814 10.5786 8.84176 11.143C9.09235 11.3815 9.4962 11.379 9.74435 11.1382C10.2236 10.6735 10.844 10.3572 11.5349 10.2526C11.5422 10.2502 11.9607 10.1821 12.4205 10.238Z"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        d="M14.7658 14.1647C14.2743 14.1647 13.7902 14.2693 13.345 14.4615V16.3567C13.7221 15.9723 14.2257 15.7606 14.7658 15.7606C15.8679 15.7606 16.7632 16.6559 16.7632 17.758C16.7632 18.8601 15.8679 19.7554 14.7658 19.7554C13.6637 19.7554 12.7684 18.8601 12.7684 17.758V16.6316C12.7684 16.6316 12.7684 16.6316 12.7708 16.6316V14.7704C12.7708 14.7704 12.7708 14.7704 12.7684 14.7704V12.1867C12.7684 11.7464 12.4108 11.3888 11.9704 11.3888C11.5301 11.3888 11.1724 11.7464 11.1724 12.1867V17.7556C11.1724 19.7359 12.783 21.3465 14.7633 21.3465C16.7437 21.3465 18.3542 19.7359 18.3542 17.7556C18.3567 15.7752 16.7461 14.1647 14.7658 14.1647Z"
                    />
                </g>
            </g>
        </svg>
    );
};
