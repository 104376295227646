import { SvgProps } from "@design-system/Icon/types";

export const Tag = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <g>
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M4.17157 4.17157C4.92172 3.42143 5.93913 3 7 3H10.859C11.6608 3.00031 12.4301 3.31893 12.9971 3.88584L21.1141 12.0018C21.114 12.0018 21.1141 12.0018 21.1141 12.0018C21.395 12.2827 21.6179 12.6162 21.7699 12.9833C21.922 13.3503 22.0002 13.7437 22.0002 14.141C22.0002 14.5383 21.922 14.9317 21.7699 15.2987C21.6179 15.6657 21.395 15.9992 21.1141 16.2801M21.1141 16.2801L16.2802 21.1141C16.2801 21.1141 16.2802 21.114 16.2802 21.1141C15.9993 21.395 15.6657 21.6179 15.2987 21.7699C14.9317 21.922 14.5383 22.0002 14.141 22.0002C13.7437 22.0002 13.3503 21.922 12.9833 21.7699C12.6163 21.6179 12.2828 21.395 12.0019 21.1141C12.0019 21.1141 12.0019 21.1141 12.0019 21.1141L3.88589 12.9981C3.31814 12.4304 3 11.6609 3 10.859V7C3 5.93913 3.42143 4.92172 4.17157 4.17157M7 5C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V10.859C5 11.1311 5.10786 11.3916 5.30011 11.5839L13.4161 19.6999C13.5113 19.7951 13.6243 19.8707 13.7487 19.9222C13.8731 19.9737 14.0064 20.0002 14.141 20.0002C14.2756 20.0002 14.4089 19.9737 14.5333 19.9222C14.6577 19.8707 14.7707 19.7951 14.8658 19.6999L19.6999 14.8659C19.7951 14.7707 19.8707 14.6577 19.9222 14.5333C19.9737 14.4089 20.0002 14.2756 20.0002 14.141C20.0002 14.0064 19.9737 13.8731 19.9222 13.7487C19.8707 13.6243 19.7951 13.5113 19.6999 13.4162L11.5829 5.30016C11.5829 5.30016 11.583 5.30016 11.5829 5.30016C11.3909 5.10816 11.1305 5.00021 10.859 5H7Z"
                        clipRule="evenodd"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        d="M8.5 9.5C9.05228 9.5 9.5 9.05228 9.5 8.5C9.5 7.94772 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.94772 7.5 8.5C7.5 9.05228 7.94772 9.5 8.5 9.5Z"
                    />
                    <path
                        strokeWidth={strokeWidth}
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M6.5 8.5C6.5 7.39543 7.39543 6.5 8.5 6.5C9.60457 6.5 10.5 7.39543 10.5 8.5C10.5 9.60457 9.60457 10.5 8.5 10.5C7.39543 10.5 6.5 9.60457 6.5 8.5Z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};
