import classNames from "classnames";

export const Loader = ({
    size = 20,
    className,
}: {
    size?: number;
    className?: string;
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            className={classNames("animate-spin rounded-full", className)}
        >
            <path
                d="M12.8194 8.33334C12.5046 8.33334 12.234 8.54803 12.1243 8.84304C11.3664 10.8803 9.38829 12.3333 7.06736 12.3333C4.09209 12.3333 1.68015 9.94553 1.68015 7.00001C1.68015 4.05449 4.09209 1.66668 7.06736 1.66668C9.38829 1.66668 11.3664 3.11968 12.1243 5.15698C12.234 5.45199 12.5046 5.66668 12.8194 5.66668V5.66668C13.2569 5.66668 13.5858 5.26355 13.4434 4.84978C12.5398 2.22293 10.0265 0.333344 7.06736 0.333344C3.34827 0.333344 0.333344 3.31811 0.333344 7.00001C0.333344 10.6819 3.34827 13.6667 7.06736 13.6667C10.0265 13.6667 12.5398 11.7771 13.4434 9.15024C13.5858 8.73647 13.2569 8.33334 12.8194 8.33334V8.33334Z"
                fill="url(#paint0_angular_4840_5981)"
            />
            <defs>
                <radialGradient
                    id="paint0_angular_4840_5981"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(13.128 6.33334) rotate(-112.193) scale(0.780011 5.39575)"
                >
                    <stop stopColor="#2685FF" />
                    <stop offset="0.515" stopColor="#F0F6FF" />
                    <stop offset="0.78" stopColor="#8FC0FF" />
                </radialGradient>
            </defs>
        </svg>
    );
};
