import { cn } from "@helpers/utils";
import classNames from "classnames";

interface Props {
    htmlFor?: string;
    required?: boolean;
    className?: string;
    children: React.ReactNode;
    size?: "default" | "large";
    bold?: "light" | "medium" | "bold";
}

export const FormLabel = ({
    children,
    required = false,
    bold = "bold",
    htmlFor,
    className = "",
    size = "default",
    ...rest
}: Props) => {
    const classes = classNames(
        {
            "text-sm": size === "default",
            "text-lg leading-6": size === "large",
            "text-gray-900": bold === "medium" || bold === "bold",
            "text-gray-600": bold === "light",
            "font-medium": bold === "medium",
            "font-semibold": bold === "bold",
            "after:content-['*'] after:ml-0.5 after:text-red-500 ": required,
            "cursor-pointer": htmlFor,
        },
        className
    );

    return (
        <label htmlFor={htmlFor} className={cn(classes)} {...rest}>
            {children}
        </label>
    );
};
