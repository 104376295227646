import { SvgProps } from "@design-system/Icon/types";

export const Signature = ({
    strokeWidth = 2,
    size = 24,
    filled = false,
    ...props
}: SvgProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    strokeWidth={strokeWidth}
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 17C6.333 13.667 8 11 8 9C8 6 7 6 6 6C5 6 3.968 7.085 4 9C4.034 11.048 5.658 13.877 6.5 15C8 17 9 17.5 10 16L12 13C12.333 15.667 13.333 17 15 17C15.53 17 17.639 15 18 15C18.517 15 19.517 15.667 21 17"
                />
            </g>
        </svg>
    );
};
